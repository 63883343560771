<template>
  <div>
    <v-toolbar color="primary" class="text-center">
      <v-img :src="require('@/assets/icons/dh-logo.png')" max-height="150" max-width="250"></v-img>
    </v-toolbar>
    <center>
      <h1>Autorizar Tiempo extra.</h1>
    </center>

    <!-- Login -->
    <div v-show="passwordValidation == false">
      <div class="center-item-in-div">
        <v-sheet max-width="400px" class="elevation-1 rounded-xl pa-5">
          <v-form ref="formPassword">
            <label class="caption blue-grey--text text-darken-1">
              Por seguridad ingresa tu contraseña del portal DH
            </label>
            <v-text-field v-model="password" prepend-inner-icon="mdi-lock-outline" class="mt-3 rounded-lg" outlined dense
              :label="$t('userPassword')" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass" required :rules="rulesPassword">
            </v-text-field>
            <v-btn block class="rounded-lg font-weight-bold" dark large color="primary" :loading="loadingBtnPsw" @click="
              loadingBtnPsw = true;
            validatePsw();
            ">
              validar contraseña
            </v-btn>
          </v-form>
        </v-sheet>
      </div>
    </div>
    <div v-show="passwordValidation == true">
      <!-- muestra un loading hasta que exista una respuesta del back -->
      <v-container fluid>
        <div v-show="loadingInfo" class="text-center">
          <h2 class="text-center blue-grey--text text--lighten-2 mb-5">Cargando contenido...</h2>
          <v-progress-circular color="primary" indeterminate :size="70" :width="7"></v-progress-circular>
        </div>
      </v-container>

      <v-container fluid v-show="opcion == 3">
        <!-- Cuando retorna datos el back muestra el detalle de la autorización general-->
        <div v-show="solicitudesaut3 == 1" class="text-center">
          <v-icon color="blue" style="font-size: 200px !important;">mdi-check-circle-outline</v-icon>
          <h1>Se autorizarón las solicitudes con exito.</h1>
        </div>

        <!-- Cuando ya estan autorizadas las solicitudes -->
        <div v-show="error3 == 1" class="text-center">
          <v-icon color="#FFB300" style="font-size: 200px !important;">mdi-alert-octagon</v-icon>
          <h1>3.- No fue posible autorizar por que existen solicitudes que ya se autorizarón.</h1>
        </div>
      </v-container>

      <v-container fluid v-show="opcion == 1">
        <!-- Cuando retorna datos el back muestra el detalle de la autorización de individual -->
        <div v-show="solicitudesaut1 == 1" class="text-center">
          <v-icon color="blue" style="font-size: 200px !important;">mdi-check-circle-outline</v-icon>
          <h1>Se autorizo la solicitud con exito.</h1>
        </div>

        <!-- Cuando las solicitudes ya estan autorizadas las horas a pagar.-->
        <div v-show="error1 == 1" class="text-center">
          <v-icon color="#FFB300" style="font-size: 200px !important;">mdi-alert-octagon</v-icon>
          <h1> Esta solicitud ya fue autorizada.</h1>
        </div>
      </v-container>

      <v-container fluid v-show="opcion == 2">
        <!-- Cuando retorna datos el back muestra el detalle de la autorización de horas a pagar -->
        <div v-show="solicitudesaut2 == 1" class="text-center">
          <v-icon color="blue" style="font-size: 200px !important;">mdi-check-circle-outline</v-icon>
          <h1>Se autorizarón las horas a pagar con exito.</h1>
        </div>

        <!-- Cuando retorna datos el back muestra el detalle de la autorización -->
        <div v-show="error2 == 1" class="text-center">
          <v-icon color="#FFB300" style="font-size: 200px !important;">mdi-alert-octagon</v-icon>
          <h1>2.- Las horas a pagar de estas solicitudes ya han sido autorizadas.</h1>
        </div>
      </v-container>

      <v-container fluid v-show="tokenlose == 1">
        <!-- Cuando el token no existe debe iniciar sesión  -->
        <div class="text-center">
          <v-icon color="#FFB300" style="font-size: 200px !important;">mdi-account-alert</v-icon>
          <h1>Debes iniciar sesión en el portal <a href="https://desarrollohumano.urrea.com/#/Login">DH2</a></h1>
        </div>
      </v-container>

      <!--Muestra en pantalla los datos que recibe de la ruta.
       <v-container style="height: 60vh;" fluid>
      <label class="lab">Folio del director: {{ folio }}</label>
      <br>
      <label class="lab">Opcion recibida: {{ opcion }}</label>
      <br>
      <label class="lab">id recibido: {{ id }}</label>
      {{datos= $route.params }}
    </v-container> -->
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";

export default {
  name: "autoriza-tiempo-extra",
  components: {},
  data() {
    return {
      datos: '',
      folio: '',
      opcion: '',
      loadingInfo: true,
      solicitudesaut1: 0,
      solicitudesaut2: 0,
      solicitudesaut3: 0,
      error1: 0,
      error2: 0,
      error3: 0,
      tokenlose: 0,

      //contraseña
      password: "",
      showPass: false,
      rulesPassword: [(v) => !!v || "La contraseña es obligatoria."],
      loadingBtnPsw: false,
      passwordValidation: false,

    };
  },
  mounted() {

  },
  methods: {
    muestradatos() {

      this.datos = this.$route.params;
      this.folio = this.datos.folio;
      this.opcion = this.datos.opc;
      console.log(this.datos);
      console.log(this.folio);
      console.log(this.opcion);

      let params = {
        Opcion: 6,
        key: this.folio,
        opcion: this.opcion,
        token: this.$route.params.folio,// localStorage.getItem("token"),
      }
      console.log(params);

      axios
        .post(
          `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`, params
        )
        .then((response) => {
          console.log(response.data);
          console.log(response.data.status);
          console.log(response.data.respuesta);

          if (response.data.status == "ERROR") {
            console.log("error");
            alert("Algo salio mal");

          } else if (response.data.status == "OK") {

            this.loadingInfo = false;

          } else if (response.data.status == "EXPRESSION") {
            //Agregar un letrero para que inicie sesion en el dh2.
            deleteToken();
          }
          if (response.data.status == "EXPSESSION") {
            this.tokenlose = 1;
            this.loadingInfo = false;
          }

          //La respuesta es 1 por que la solicitud no esta autorizada.
          console.log(response.data.respuesta);
        
          if (response.data.respuesta == 1) {
            if (this.opcion == 1) {
              this.solicitudesaut1 = 1;
              this.loadingInfo = false;
              this.error2 = 0;
              this.error3 = 0;
              this.solicitudesaut2 = 0;
              this.solicitudesaut3 = 0;
            }

              if (this.opcion == 2) {
                this.solicitudesaut2 = 1;
                this.loadingInfo = false;
                this.error1 = 0;
                this.error3 = 0;
                this.solicitudesaut1 = 0;
                this.solicitudesaut3 = 0;
              }

                if (this.opcion == 3) {
                  this.solicitudesaut3 = 1;
                  this.loadingInfo = false;
                  this.error1 = 0;
                  this.error2 = 0;
                  this.solicitudesaut1 = 0;
                  this.solicitudesaut2 = 0;
                }
          } else {
            //La respuesta es 2  o 3 por que la solicitud ya esta autorizada.
            console.log(response.data.respuesta);
            console.log(this.opcion);
            if (this.opcion == 1) {
              console.log("Si llegue");
              this.error1 = 1;
              this.loadingInfo = false;
              this.error2 = 0;
              this.error3 = 0;
              this.solicitudesaut1 = 0;
              this.solicitudesaut2 = 0;
              this.solicitudesaut3 = 0;
            }

              if (this.opcion == 2) {
                this.error2 = 1;
                this.loadingInfo = false;
                this.error1 = 0;
                this.error3 = 0;
                this.solicitudesaut1 = 0;
                this.solicitudesaut2 = 0;
                this.solicitudesaut3 = 0;
              }

                if (this.opcion == 3) {
                  this.error3 = 1;
                  this.loadingInfo = false;
                  this.error1 = 0;
                  this.error2 = 0;
                  this.solicitudesaut1 = 0;
                  this.solicitudesaut2 = 0;
                  this.solicitudesaut3 = 0;
                }

          }


        })
        .catch(function (e) {
          alert("Ocurrió un error: " + e);

        });

    },
    validatePsw() {
      if (this.$refs.formPassword.validate()) {
        let params = {
          Opcion: 8,
          key: this.$route.params.folio,
          pass: this.password,
          token: this.$route.params.folio, // localStorage.getItem("token"),
        };
        axios
          .post(
            `${this.$store.getters.getHost}/general/ws-TiempoExtra.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.empleado = response.data.respuesta;
              this.passwordValidation = true;

              //Una vez que la contraseña sea la correcta se autoriza la solicitud correspondiente.
              this.muestradatos();

            } else {
              alert("Contraseña inválida.");
            }
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.loadingBtnPsw = false;
          });
      } else {
        this.loadingBtnPsw = false;
      }
    },
  }
}
</script>