<template>
  <!------------------------------------------------ TEMPLATE -->
  <section>
    <v-sheet class="mx-auto mb-6">
      <v-img
        :src="require('@/assets/logo-urrea-dh-azul.png')"
        max-height="60"
        max-width="226"
      >
      </v-img>
    </v-sheet>
    <v-sheet max-width="200">
      <v-select
        dense
        rounded
        solo
        v-model="$i18n.locale"
        prepend-inner-icon="mdi-translate"
        :items="languages"
        item-text="title"
        item-value="key"
        label="$t('textCompany')"
        single-line
      ></v-select>
    </v-sheet>
    <v-form ref="loginForm" @submit.prevent="handleLogin">
      <v-card-text class="px-4 py-0">
        <v-text-field
          v-model="user.sUsuario"
          class="rounded-pill"
          outlined
          flat
          dense
          prepend-inner-icon="mdi-account-outline"
          :rules="rules"
          :label="$t('userTextName')"
          required
        >
        </v-text-field>
        <v-text-field
          v-model="user.sPassword"
          prepend-inner-icon="mdi-lock-outline"
          class="mt-0 pt-0 rounded-pill"
          outlined
          dense
          :rules="rules"
          :label="$t('userPassword')"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          required
        >
        </v-text-field>
        <v-btn
          color="primary"
          class="rounded-pill"
          depressed
          block
          :loading="loadBtn"
          :disabled="loadBtn"
          type="submit"
          @click="validate"
        >
          {{ $t("textButton") }}
        </v-btn>

        <v-btn
          class="font-weight-bold text-decoration-underline"
          color="blue darken-1"
          text
          x-small
          @click="recuperaContrasena = true"
        >
          {{ $t("recuperaContraseña") }}
        </v-btn>
      </v-card-text>
    </v-form>
    <template>
      <template>
        <div class="text-center">
          <v-snackbar v-model="snackbar" color="error">
            {{ text }}
            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </template>
    </template>

    <v-dialog v-model="recuperaContrasena" max-width="500" persistent>
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text text-subtitle-2">
          {{ $t("txtTitleRecPass") }}
          <v-spacer></v-spacer>
          <v-icon dark @click="recuperaContrasena = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-alert
            v-model="showAlert"
            class="mt-3"
            border="top"
            dense
            :color="typeAlert"
            dark
            dismissible
          >
            {{ textAlert }}
          </v-alert>
          <v-form
            v-model="validResPassword"
            lazy-validation
            ref="formResPassword"
          >
            <v-text-field
              v-model="nickName"
              class="mt-3 rounded-lg"
              outlined
              dense
              :rules="rules"
              :label="$t('labResetPassFieldNick')"
              :placeholder="$t('labResetPassPlaceHoldNick')"
            >
            </v-text-field>
            <v-text-field
              v-model="numEmpleado"
              class="mb-0 rounded-lg"
              outlined
              dense
              :rules="rules"
              :label="$t('labResetPassField')"
              :placeholder="$t('labResetPassPlaceHold')"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg fond-weight-bold"
            depressed
            dark
            color="teal darken-1"
            :loading="loadResetPass"
            @click="
              loadResetPass = true;
              resetPass();
            "
          >
          </v-btn>

          <v-btn
            class="rounded-lg fond-weight-bold"
            depressed
            dark
            color="teal darken-1"
            :loading="loadResetPass"
            @click="
              loadResetPass = true;
              resetPass();
            "
          >
            {{ $t("txtTitleRecPass") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
// ---------------------------------------------- CONTROLLER
import axios from "axios";
import { mapActions } from "vuex";

export default {
  name: "general-login-form",
  data() {
    return {
      valid: false,
      validResPassword: true,
      recuperaContrasena: false,
      loadResetPass: false,
      showAlert: false,
      typeAlert: "",
      textAlert: "",
      numEmpleado: null,
      lang: "es",
      languages: languages,
      user: {
        sUsuario: "",
        sPassword: "",
      },
      showPass: false,
      rules: [(v) => !!v || "Campo requerido"],
      loadBtn: false,
      snackbar: false,
      text: "",
      nickName: "",
    };
  },
  mounted() {
    var lang = localStorage.getItem("lang");
    if (lang) this.changeLanguage(lang);
  },
  methods: {
    ...mapActions({
      retrieveMenu: "retrieveMenu",
    }),
    validate() {
      this.$refs.loginForm.validate();
    },
    handleLogin() {
      localStorage.setItem("token", null);
      if (this.$refs.loginForm.validate()) {
        localStorage.setItem("lang", this.lang);
        this.loadBtn = true;
        let data = { ...params, ...this.user };
        axios
          .post(`${this.$store.getters.getHost}/Login/ws-login.php`, data)
          // .post(`${this.$store.getters.getHostNode}/api/login`, data)
          //.post('http://localhost:3080/api/login', data)
          .then((token) => {
            //console.log(JSON.stringify(token, null, 2))
            if (token.data.status == "OK") {
              if (token.data.token) {
                // if (token.data.data) {
                localStorage.setItem("token", token.data.token);
              }
              this.$store.dispatch("retrieveUser").then(() => {
                this.$store.dispatch("retrieveMenu").then(() => {
                  let path = "";
                  if (this.$store.getters.getUser["compania"] == "CANDIDATO") {
                    path = "InicioPresentacionView";
                  } else {
                    const tipoMenu = this.$store.getters.getMenuList.find(
                      (elemento) => elemento.title === "Inicio"
                    );
                    if (tipoMenu) {
                      if (this.$store.getters.getUser["Folio"] == "0") {
                        path = "/MisObjetivos";
                      } else if (this.$store.getters.getUser["EstatusPass"] == 1) {
                        path = this.$store.getters.isMultisistema
                          ? "/DHGeneral/Inicio"
                          : "Inicio";
                      } else {
                        path = this.$store.getters.isMultisistema
                          ? "/DHGeneral/CambioPass"
                          : "CambioPass";
                      }
                    } else {
                      path = "RepseUsuarios";
                    }
                  }

                  this.$router.push(path);
                });
              });
            } else {
              this.text = "Credenciales incorrectas";
              this.snackbar = true;
            }
          })
          .finally(() => (this.loadBtn = false));
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
    },
    resetPass() {
      if (this.$refs.formResPassword.validate()) {
        let params = {
          Opcion: 6,
          emplid: this.numEmpleado,
          nickname: this.nickName,
        };

        axios
          .post(`${this.$store.getters.getHost}/Login/ws-login.php`, params)
          .then((response) => {
            this.loadResetPass = false;
            if (response.data.status == "ERROR") {
              this.typeAlert = "error";
              switch (response.data.error) {
                case "1001": {
                  this.textAlert = this.$t("errorDataResetPass");
                  this.showAlert = true;
                  break;
                }
                case "1002": {
                  this.textAlert =
                    this.$t("DH360.errorMessaggeDB") +
                    ". " +
                    response.data.error;
                  this.showAlert = true;
                  break;
                }
                case "1003": {
                  this.textAlert =
                    this.$t("DH360.errorMessaggeDB") +
                    ". " +
                    response.data.error;
                  this.showAlert = true;
                  break;
                }
                case "1004": {
                  this.textAlert =
                    this.$t("DH360.errorMessaggeDB") +
                    ". " +
                    response.data.error;
                  this.showAlert = true;
                  break;
                }
                case "1005": {
                  this.textAlert =
                    this.$t("DH360.errorMessaggeDB") +
                    ". " +
                    response.data.error;
                  this.showAlert = true;
                  break;
                }
              }
            } else if (response.data.status == "OK") {
              this.$refs.formResPassword.reset();
              this.$refs.formResPassword.resetValidation();
              this.typeAlert = "success";
              switch (response.data.error) {
                case "1006": {
                  this.textAlert = this.$t("susscessMsgPass");
                  this.showAlert = true;
                  break;
                }
                case "1007": {
                  this.textAlert = this.$t("susscessMsgPassGen");
                  this.showAlert = true;
                  break;
                }
              }
            }
          });
      } else {
        this.loadResetPass = false;
      }
    },
  },
};

const languages = [
  { key: "es", title: "Español" },
  { key: "en", title: "English" },
];

const params = {
  Opcion: 1,
  sistema: 1019,
  perfil: null,
};
</script>

<style lang="sass" scoped>
 // ---------------------------------------------- STYLES
.input-field
  background: none
  border: none
  outline: none
  width: 100%
  color: rgb(0, 255, 200)
</style>
