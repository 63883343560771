<template>
  <div style="height: 100vh;">
    <v-toolbar color="primary" class="header-toolbar" width="100%">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        :max-width="isSmallScreen ? '50%' : '250'"
      ></v-img>
      <v-spacer v-show="isSmallScreen"></v-spacer>
      <v-btn
        v-show="isSmallScreen"
        elevation="1"
        color="blue-grey lighten-5"
        class="primary--text"
        @click="verVacantes = true"
      >
        <v-icon left color="primary">mdi-briefcase</v-icon>
        ver vacantes
      </v-btn>
    </v-toolbar>
    <div style="background-color: #FFFFFF;" class="mt-10">
      <div class="" id="m" style="padding: 3rem 1.5rem 0rem 1.5rem;">
        <v-card class="rounded-xl elevation-0" height="100%">
          <v-carousel
            cycle
            :height="isSmallScreen ? '120px' : '350px'"
            width="100%"
            hide-delimiter-background
            show-arrows-on-hove
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="require('@/assets/img/' + item.src)"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </div>
    </div>
    <svg
      v-show="(!verVacantes && isSmallScreen) || !isSmallScreen"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      :class="!isSmallScreen ? 'top-margin-wave' : ''"
    >
      <path
        fill="#0078C3"
        fill-opacity="1"
        d="M0,128L80,128C160,128,320,128,480,138.7C640,149,800,171,960,186.7C1120,203,1280,213,1360,218.7L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
    <div class="fondo-desc text-center">
      <v-container>
        <label
          class="white--text text-center font-weight-bold text-uppercase"
          style="text-align: center;"
          >grupo urrea</label
        >
        <p class="white--text">
          Somos una empresa 100% Mexicana con presencia global, con más de 100
          años de vida. Grupo Urrea está integrado por dos divisiones: “Dando
          vida al agua” y “Solución Total en Herramientas y Cerrajería” ambas
          líderes en sus respectivos mercados. Sus marcas satisfacen las
          necesidades de los usuarios y son reconocidas en más de 20 países del
          continente americano, incluyendo los EUA.
        </p>
        <p class="white--text">
          La División Herramientas y Cerrajería nace de la asociación entre
          Grupo Urrea y un fabricante de herramienta en EUA, para crear
          Protomex, antecesor de Grupo Urrea División Herramientas.
          Posteriormente con la integración de la marca LOCK al portafolio de
          esta división, se da origen a lo que actualmente conocemos como “Grupo
          Urrea Solución Total en Herramientas y Cerrajería”.
        </p>
      </v-container>
    </div>
    <svg
      v-show="(!verVacantes && isSmallScreen) || !isSmallScreen"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill="#0078C3"
        fill-opacity="1"
        d="M0,128L80,128C160,128,320,128,480,138.7C640,149,800,171,960,186.7C1120,203,1280,213,1360,218.7L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
      ></path>
    </svg>
    <div
      v-show="(!verVacantes && isSmallScreen) || !isSmallScreen"
      class="center-item-in-div-column top-margin-img"
    >
      <v-img
        src="https://www.urrea.com/urrea/images/trabaja/premios_certificaciones.png"
      ></v-img>
      <v-img
        src="https://www.urrea.com/urrea/images/trabaja/logo_123.png"
      ></v-img>
    </div>
    <div
      v-show="(!verVacantes && isSmallScreen) || !isSmallScreen"
      style="background-color: #FFFFFF;"
      class="pb-15 pt-0"
    >
      <div class="text-center title-effect text-title">
        Nuestros valores
      </div>
      <v-container>
        <p class="text-center subtitle-1" style="color: rgb(0,120,195);">
          Vivimos nuestros valores año tras año, nuestra cultura crece.
        </p>
        <div class="cards">
          <v-card
            class="fix-cards elevation-0"
            max-width="350"
            :height="!isSmallScreen ? 450 : 300"
          >
            <v-card-title style="align-items: end;">
              <v-img
                :src="require('@/assets/icons/valores-pasion-sinFondo.png')"
                max-width="25%"
              ></v-img>
              <span
                class="text-h4 font-weight-light pink--text mb-2 ml-5 text-center"
                >Pasión</span
              >
            </v-card-title>
            <v-card-text class="pt-5 pb-10 px-12">
              <div
                class="text-justify"
                :class="!isSmallScreen ? 'text-h6' : 'subtitle-2'"
              >
                Trabajamos con absoluta satisfación, placer y pasión por lo que
                hacemos. Para mi no es un simple trabajo, es "mi trabajo", me
                enorgullece y me apasiona.
              </div>
            </v-card-text>
          </v-card>
          <v-card
            class="fix-cards elevation-0"
            max-width="350"
            :height="!isSmallScreen ? 450 : 300"
          >
            <v-card-title style="align-items: end;">
              <v-img
                :src="require('@/assets/icons/valores-integridad-sinFondo.png')"
                max-width="25%"
              ></v-img>
              <span
                class="text-h4 font-weight-light pink--text mb-2 ml-5 text-center"
                style="color: #edd80f !important;"
                >Integridad</span
              >
            </v-card-title>
            <v-card-text class="pt-5 pb-10 px-12">
              <div
                class="text-justify"
                :class="!isSmallScreen ? 'text-h6' : 'subtitle-2'"
              >
                Ponemos al máximo nuestras capacidades al realizar todo aquello
                que se nos ha confiado, viviendo y respetando los principios y
                valores que nos distinguen y caracterizan.
              </div>
            </v-card-text>
          </v-card>
          <v-card
            class="fix-cards elevation-0"
            max-width="350"
            :height="!isSmallScreen ? 450 : 300"
          >
            <v-card-title style="align-items: end;">
              <v-img
                :src="require('@/assets/icons/valores-respeto-sinFondo.png')"
                max-width="25%"
              ></v-img>
              <span
                class="text-h4 font-weight-light blue--text text--darken-4 mb-2 ml-5 text-center"
                >Respeto</span
              >
            </v-card-title>
            <v-card-text class="pt-5 pb-10 px-12">
              <div
                class="text-justify"
                :class="!isSmallScreen ? 'text-h6' : 'subtitle-2'"
              >
                Nos desempeñamos en la cultura de la convivencia armónica,
                basada en la confianza, compromiso y comprensión a nuestros
                compañeros a la empresa misma y nuestro entorno general
              </div>
            </v-card-text>
          </v-card>
        </div>
        <p class="text-center pt-5">
          💖 Pasión, ✅ integridad y 👩🔧 respeto son los valores que
          representan a todos los colaboradores que formamos parte de esta gran
          familia.
        </p>
      </v-container>
    </div>
    <div
      v-show="(!verVacantes && isSmallScreen) || !isSmallScreen"
      class="pb-15"
      :class="
        !isSmallScreen ? 'center-item-in-div ' : 'center-item-in-div-column'
      "
    >
      <v-card
        class="rounded-circle mx-1 pa-5 center-item-in-div-column"
        color="#0078c3"
        width="300"
        height="300"
        elevation="0"
      >
        <v-icon dark x-large> mdi-medal-outline </v-icon>
        <label class="font-weight-bold text-uppercase white--text"
          >misión</label
        >
        <p class="text-center mb-0 white--text">
          Somos una empresa con marcas líderes que ofrecen las mejores
          soluciones en herramientas y productos ferreteros
        </p>
      </v-card>
      <v-card
        class="rounded-circle mx-1 pa-5 center-item-in-div-column"
        color="#90A4AE"
        width="300"
        height="300"
        elevation="0"
      >
        <v-icon dark x-large> mdi-chart-line</v-icon>
        <label class="font-weight-bold text-uppercase white--text"
          >visión</label
        >
        <p class="text-center mb-0 white--text">
          Ser la mejor empresa para nuestros clientes, distribuidores,
          colaboradores y accionistas con soluciones innovadoras
        </p>
      </v-card>
      <v-card
        class="rounded-circle mx-1 pa-5 center-item-in-div-column"
        color="#0078c3"
        width="300"
        height="300"
        elevation="0"
      >
        <v-icon dark x-large> mdi-hand-coin-outline</v-icon>
        <label class="font-weight-bold text-uppercase white--text"
          >propuesta de valor</label
        >
        <p class="text-center mb-0 white--text">
          Ser el proveedor + simple, + rápido, + seguro. Ofreciendo una solución
          total en herramientas y productos ferreteros
        </p>
      </v-card>
      <v-card
        class="rounded-circle mx-1 pa-5 center-item-in-div-column"
        color="#90A4AE"
        width="300"
        height="300"
        elevation="0"
      >
        <v-icon dark x-large> mdi-lightbulb-on-outline </v-icon>
        <label class="font-weight-bold text-uppercase white--text"
          >política de calidad</label
        >
        <p class="text-center mb-0 white--text">
          Mejoramos e innovamos continuamente los procesos y productos para que
          sean más simples, más rápidos, más seguros
        </p>
      </v-card>
    </div>
    <!-- <v-tooltip left color="primary" v-if="$vuetify.breakpoint.name != 'xs'"> -->
    <!-- <template v-slot:activator="{ on, attrs }"> -->
    <v-menu
      v-model="popover"
      :disabled="!popover"
      content-class="fix-border-dialog mb-2"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-y
      offset-x
      top
    >
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip
          left
          color="blue-grey lighten-5"
          v-if="$vuetify.breakpoint.name != 'xs'"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="flicker"
              large
              fixed
              bottom
              right
              fab
              color="blue-grey lighten-5"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              @click="verVacantes = true"
            >
              <v-badge color="error" content="2">
                <v-icon color="primary">mdi-chat</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <div class="primary--text">
            ¡Únete al equipo! {{ String.fromCodePoint(0x1f916) }}
          </div>
        </v-tooltip>
      </template>

      <v-card
        class="rounded-pill py-2 px-3 elevation-0"
        color="accent"
        style="display: flex; justify-content: space-between;"
      >
        <span>¡Postulate aquí! {{ String.fromCodePoint(0x1f916) }}</span>
        <v-icon @click="popover = false" small>mdi-close</v-icon>
      </v-card>
    </v-menu>
    <!-- </template> -->

    <!-- <span>¡Únete al equipo! {{ String.fromCodePoint(0x1f916) }}</span>
    </v-tooltip> -->

    <v-footer
      v-show="(!verVacantes && isSmallScreen) || !isSmallScreen"
      class="pb-10"
      dark
      padless
    >
      <v-card flat tile color="#546E7A" class="white--text text-center">
        <v-card-text>
          <v-btn
            v-for="(icon, index) in icons"
            :key="index"
            class="mx-4 white--text"
            icon
            @click="openLink(icon.link)"
          >
            <v-icon size="24px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          Grupo Urrea, empresa mexicana con {{ edadUrrea }} años de experiencia
          y presencia a nivel global, ofrece soluciones en herramientas y
          cerrajería con un enfoque hacia la calidad, innovación y tecnología.
          Sus marcas principales son: Urrea, líder en herramienta especializada
          que cumple estándares superiordes de calidad y desempeño para uso
          pesado y continuo, Surtek, herramientas para profesionales y productos
          ferreteros con amplio portafolio de opciones para todas las
          necesidades y Lock, soluciones en cerrajería y seguridad. Grupo Urrea
          es la única empresa que manufactura herramientas mecánicas en México.
          <a href="http://www.urrea.com" class="enlace">Website urrea.com</a>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          <strong>Tecnología de la información</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <AluExterno
      v-if="verVacantes"
      :verAluExterno="verVacantes"
      @cierraAlu="verVacantes = false"
    />
  </div>
</template>

<script>
import AluExterno from "./PersonaAluExterno2.vue";
export default {
  name: "atraccion-de-talento",
  components: { AluExterno },
  data() {
    return {
      icons: [
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/GrupoUrreaHerramientasyCerrajeria",
        },
        {
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/company/grupo-urrea/",
        },
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/grupourrea/",
        },
      ],
      items: [
        {
          src: "banner-entrada-urrea.png",
        },
        {
          src: "banner-empleados.png",
        },
        {
          src: "banner-empleado-1.png",
        },
        {
          src: "banner-empleado-2.png",
        },
        {
          src: "banner-marcas.png",
        },
      ],
      verVacantes: false,
      popover: true,
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
        ? true
        : false;
    },
    edadUrrea() {
      const fechaInicioObj = new Date("1907-10-12");
      const fechaFinObj = new Date();

      const diferenciaEnMilisegundos = fechaFinObj - fechaInicioObj;
      const anios = Math.floor(
        diferenciaEnMilisegundos / (1000 * 60 * 60 * 24 * 365.25)
      );

      return anios;
    },
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.parallax {
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 25rem;
  max-width: 100%;
  position: relative;
}

.img-uno {
  background-image: url("https://www.urrea.com/urrea/images/infraestructura/centro-de-distribucion.jpg");
  position: relative;
  z-index: 1;
}

.cards {
  display: flex;
  justify-content: space-around;
}

.fix-cards {
  border-radius: 50px !important;
  background: #ffffff !important;
  cursor: pointer;
  box-shadow: 11px 11px 22px #e0e0e0, -11px -11px 22px #e0e0e0 !important;
}

.cards .fix-cards:hover {
  transform: scale(1.05, 1.05);
}

.cards:hover > .fix-cards:not(:hover) {
  filter: blur(1px);
  transform: scale(0.9, 0.9);
}

.text-title {
  font-size: 2.5rem;
  font-weight: bolder;
}
.title-effect {
  background: #0078c3 !important;
  background: linear-gradient(to right, #0078c3 13%, #81d2c7 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.fondo-desc {
  background-color: #0078c3;
  width: 100%;
  min-height: 200px;
  margin-top: -44px;
}

.top-margin-wave {
  margin-top: -108px;
}

.top-margin-img {
  margin-top: -100px;
}

.header-toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; /* Z-index para asegurar que el header se muestre por encima del resto del contenido */
}

.enlace {
  color: #80d8ff;
}

@media (max-width: 768px) {
  .fondo-desc {
    background-color: #0078c3;
    width: 100%;
    min-height: 200px;
    margin-top: -22px;
  }
  .cards {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .fix-cards {
    border-radius: 50px !important;
    background: #ffffff !important;
    cursor: pointer;
    box-shadow: 5px 5px 10px #e0e0e0, -5px -5px 10px #e0e0e0 !important;
    margin-bottom: 15px;
  }

  .cards .fix-cards:hover {
    transform: scale(1.05, 1.05);
  }

  .cards:hover > .fix-cards:not(:hover) {
    filter: blur(0px);
  }

  .top-margin-img {
    margin-top: -50px;
  }
}
</style>
