<template>
  <div>
    <v-dialog v-model="dialogPrincipal" fullscreen scrollable>
      <v-card class="rounded-0" color="blue-grey lighten-5">
        <v-card-title class="primary py-1 center-item-in-div">
          <v-img
            :src="require('@/assets/icons/dh-logo.png')"
            max-height="40"
            max-width="150"
          ></v-img>
        </v-card-title>
        <v-card-text :class="isSmallScreen ? 'pa-0' : 'pa-5'">
          <v-card
            class="elevation-0 pa-5"
            :class="isSmallScreen ? 'rounded-0' : 'rounded-xl'"
            width="100%"
            color="light-blue darken-2"
            :style="
              isSmallScreen
                ? ''
                : 'display: flex; justify-content: center; align-items: center;'
            "
          >
            <div
              v-if="loadingVacante"
              style="min-height: 300px;"
              class="center-item-in-div-column"
            >
              <div
                class="text-center overline white--text my-5 font-weight-bold"
              >
                Cargando información...
              </div>
              <v-progress-circular
                :size="90"
                :width="10"
                color="white"
                indeterminate
              ></v-progress-circular>
            </div>
            <v-row
              v-else-if="
                !loadingVacante &&
                  JSON.stringify(detalleVacante) != '{}' &&
                    detalleVacante != null
              "
              height="100%"
            >
              <v-col
                :cols="isSmallScreen ? 12 : 6"
                :style="
                  isSmallScreen
                    ? 'height: 90vh;'
                    : 'display: flex; flex-direction: column; align-items: flex-start; justify-content: center;'
                "
              >
                <div
                  class="white--text font-weight-bold"
                  :class="isSmallScreen ? 'subtitle-1' : 'text-h6'"
                >
                  Fuiste invitad@ por Reclutamiento UHM
                </div>
                <div class="white--text font-weight-bold text-h5">
                  A postularte a la vacante de
                </div>
                <div
                  class="white--text font-weight-black text-h3"
                  :class="isSmallScreen ? 'text-h4' : 'text-h3'"
                >
                  {{ detalleVacante.Nombre }}
                </div>
                <v-card
                  class="rounded-lg elevation-0 my-1"
                  color="blue lighten-1"
                  :width="isSmallScreen ? '95%' : '80%'"
                >
                  <v-card-title
                    class="subtitle-1 py-1 text-uppercase blue--text text--lighten-5 font-weight-bold"
                    >Descripción del puesto</v-card-title
                  >
                  <v-card-text
                    class="text-justify blue--text text--lighten-5"
                    :class="isSmallScreen ? 'caption' : 'body-1'"
                  >
                    {{
                      detalleVacante.productivity == null
                        ? detalleVacante.mercer
                        : detalleVacante.productivity
                    }}
                  </v-card-text>
                </v-card>
                <v-card
                  class="rounded-lg elevation-0 my-1"
                  color="light-blue darken-1"
                  :width="isSmallScreen ? '95%' : '80%'"
                  v-if="apego !== null"
                >
                  <v-card-title
                    class="subtitle-1 py-1 text-uppercase blue--text text--lighten-5 font-weight-bold"
                    >Requisitos</v-card-title
                  >
                  <v-card-text
                    class="blue--text text--lighten-5"
                    :class="isSmallScreen ? 'caption' : 'body-1'"
                  >
                    <div class="overline font-weight-bold">escolaridad</div>
                    <div class="body-1">
                      <strong>{{ apego.escolaridad.esc }}</strong> -
                      {{ apego.escolaridad.carrera }}
                    </div>
                    <div class="overline font-weight-bold">
                      Conocimientos técnicos
                    </div>
                    <ul>
                      <li
                        class="text-uppercase body-2"
                        v-for="(item, index) in apego.conocimientos"
                        :key="index"
                      >
                        <strong>({{ item.Ponderacion }}%)</strong> -
                        {{ item.descr }}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                :cols="isSmallScreen ? 12 : 6"
                class="center-item-in-div"
                :style="isSmallScreen ? 'height: 90vh;' : ''"
              >
                <v-card class="rounded-xl elevation-15 pa-5">
                  {{ limiteSuperior }} - {{ preguntas.length }}
                  <v-form ref="form">
                    <div
                      v-for="(item, index) in preguntas.slice(
                        this.limiteInferior,
                        this.limiteSuperior
                      )"
                      :key="index"
                    >
                      <label class="caption text-uppercase">
                        {{ item.pregunta }}
                      </label>
                      <v-text-field
                        v-model="item.Respuesta"
                        class="rounded-lg"
                        outlined
                        required
                        :rules="rules"
                        dense
                      ></v-text-field>
                    </div>
                  </v-form>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="rounded-pill"
                      color="teal darken-1"
                      large
                      depressed
                      dark
                      v-if="limiteSuperior === preguntas.length"
                      @click="
                        loadingVacante = true;
                        saveResponses();
                      "
                    >
                      guardar
                      <v-icon right> mdi-content-save </v-icon>
                    </v-btn>
                    <v-btn
                      class="rounded-pill"
                      color="teal darken-1"
                      large
                      depressed
                      dark
                      v-else
                      @click="nextQuestion"
                    >
                      Continuar
                      <v-icon right> mdi-arrow-right </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <div
              v-else
              style="min-height: 300px;"
              class="center-item-in-div-column"
            >
              <v-card class="rounded-xl px-5 py-10 elevation-0 blue-grey lighten-5">
                <div
                  class="text-center subtitle-1 my-5 font-weight-bold text-uppercase blue--text text--darken-3"
                >
                  Parece que esta invitación ya caducó. Esto puede ser porque se
                  alcanzó la fecha límite o el candidato ya se postuló.
                </div>
                <br />
                <div class="text-center subtitle-1 my-5 font-weight-bold blue-grey--text text--lighten-1">
                  Para más información puedes contactar a
                  reclutamiento@urrea.net.
                </div>
              </v-card>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDialogWarningTerms"
      persistent
      max-width="400"
      content-class="fix-border-dialog"
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="80"
            ></v-img>
          </div>
          <p
            class="subtitle-2 text-uppercase font-weight-bold text-center mb-3"
          >
            Antes de registrarse
          </p>
          <p class="subtitle-2 text-uppercase  text-center mb-3">
            Para continuar es necesario que aceptes el
            <a @click="showTerms = true">Acuerdo de confidencialidad</a> del
            proceso de reclutamiento
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            class="rounded-pill"
            color="teal darken-1"
            dark
            depressed
            @click="showDialogWarningTerms = false"
          >
            Aceptar acuerdo de confidencialidad
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showTerms"
      persistent
      max-width="650"
      content-class="fix-border-dialog"
      scrollable
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-title class="font-weight-bold subtitle-1">
          Acuerdo de confidencialidad
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <p class="text-justify body-2">
            Acuerdo de confidencialidad que celebran, por una parte, EL
            CANDIDATO y por otra
            <strong>URREA HERRAMIENTAS PROFESIONALES S.A. DE C.V.</strong>
            Representada en este acto por TSIPIN IRIETA AMEZCUA REYES, en su
            carácter de apoderado legal, en adelante LA EMPRESA, así mismo, en
            lo sucesivo se denominarán en forma conjunta e indistinta LAS
            PARTES, quienes han decidido libre y voluntariamente ceñirse al
            tenor de las siguientes declaraciones y cláusulas:
          </p>
          <div class="subtitle-2 text-uppercase font-weight-bold">
            declaraciones
          </div>
          <ul class="text-justify body-2">
            <li>
              Que EL CANDIDATO se encuentra participando en un proceso de
              selección para la vacante de “{{ detalleVacante.Nombre }}”.
            </li>
            <li>
              Que, sin perjuicio de las obligaciones contenidas en la ley
              federal del trabajo, así como ley federal de protección de datos
              en posesión de particulares, se considera necesario especificar el
              más amplio alcance de dichas obligaciones.
            </li>
          </ul>
          <p class="text-justify body-2">
            En atención a las declaraciones expuestas, LAS PARTES acuerdan:
          </p>
          <div class="subtitle-2 text-uppercase font-weight-bold">
            Primero. Confidencialidad.
          </div>
          <ul class="text-justify body-2">
            <li>
              • EL CANDIDATO se obliga en forma irrevocable ante LA EMPRESA a no
              revelar, divulgar o facilitar-bajo cualquier forma a ninguna
              persona física o jurídica, sea esta pública o privada, y a no
              utilizar para su propio beneficio o para beneficio de cualquier
              otra persona física o jurídica, pública o privada, toda la
              información relacionada con el ejercicio de su proceso, como así
              también las políticas y/o cualquier otra información vinculada con
              sus funciones y/o giro comercial de LA EMPRESA.
            </li>
            <li>
              • EL CANDIDATO asume la obligación de confidencialidad acordada en
              el presente artículo por todo el plazo de la relación de selección
              para el puesto y por un plazo hasta la contratación.
            </li>
            <li>
              • De igual forma, es obligación EL CANDIDATO. - Guardar
              escrupulosamente los secretos técnicos, comerciales y de
              fabricación de los productos a cuya elaboración concurran directa
              o indirectamente, o de los cuales tengan conocimiento por razón
              del trabajo que desempeñen, así como de los asuntos
              administrativos reservados, cuya divulgación puede causar
              perjuicios a la empresa.
            </li>
          </ul>
          <br />
          <div class="subtitle-2 text-uppercase font-weight-bold">
            fundamentos legales
          </div>
          <p class="text-justify body-2">
            <strong
              >LFPDPP.- DISPOSICIONES GENERALES.- CAPITULO II.- De los
              principios de protección de datos personales.- Artículo
              21.-</strong
            >
            El responsable o terceros que intervengan en cualquier fase de
            tratamiento de datos personales deberán guardar confidencialidad
            respecto de éstos, obligación que subsistirá aun después de
            finalizar sus relaciones con el titular o, en su caso, con el
            responsable.
          </p>
          <p class="text-justify body-2">
            <strong>LFDPP.- Art. 63 Fracción XVII.-</strong> Tratar los datos
            personales de manera que se afecte o impida el ejercicio de los
            derechos de acceso, rectificación, cancelación y oposición
            establecidos en el artículo 16 de la Constitución Política de los
            Estados Unidos Mexicanos.
          </p>
          <p class="text-justify body-2">
            <strong>LFDPP.- Artículo 67.-</strong> Se impondrá de tres meses a
            tres años de prisión al que estando autorizado para tratar datos
            personales, con ánimo de lucro, provoque una vulneración de
            seguridad a las bases de datos bajo su custodia.
          </p>
          <p class="text-justify body-2">
            <strong>LFDPP.- Artículo 68.-</strong> Se sancionará con prisión de
            seis meses a cinco años al que, con el fin de alcanzar un lucro
            indebido, trate datos personales mediante el engaño, aprovechándose
            del error en que se encuentre el titular o la persona autorizada
            para trasmitirlos.
          </p>
          <div class="text-center body-2 text-uppercase font-weight-bold">
            URREA HERRAMIENTAS PROFESIONALES S.A. DE C.V
          </div>
          <div class="text-center body-2 font-weight-bold">
            A través de su apoderado legal
          </div>
          <div class="text-center body-2 font-weight-bold text-uppercase">
            TSIPIN IRIETA AMEZCUA REYES
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            block
            class="rounded-pill"
            color="teal darken-1"
            dark
            depressed
            @click="showTerms = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
// import { deleteToken } from "../store/Funciones/funciones";
import SnackBar from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import axios from "axios";
export default {
  name: "formulario-material-ingresar",
  components: { SnackBar },
  data() {
    return {
      dialogPrincipal: true,
      loadingVacante: true,
      showTerms: false,
      showDialogWarningTerms: false,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
      limiteInferior: 0,
      limiteSuperior: 6,
      preguntas: [],
      preguntasBase: [
        { id: 1, pregunta: "¿Cuál es tu nombre completo?", Respuesta: "" },
        { id: 2, pregunta: "Ingresa tu CURP", Respuesta: "" },
        {
          id: 3,
          pregunta: "¿Cuál es tu último nivel de estudios?",
          Respuesta: "",
        },
        { id: 4, pregunta: "Ingresa tu número de teléfono", Respuesta: "" },
        {
          id: 5,
          pregunta: "Ingresa por favor tu dirección de correo electrónico",
          Respuesta: "",
        },
        {
          id: 6,
          pregunta:
            "¿Cuál es el tiempo de traslado que te tomaría llegar a la empresa?",
          Respuesta: "",
        },
        {
          id: 7,
          pregunta:
            "¿Cuál fue tu ultimo puesto y cual fue la permanencia en este?",
          Respuesta: "",
        },
        {
          id: 8,
          pregunta:
            "¿Cuál fue tu penultimo puesto y cual fue la permanencia en este?",
          Respuesta: "",
        },
        {
          id: 9,
          pregunta: "¿Cuál fue tu último sueldo percibido?",
          Respuesta: "",
        },
        { id: 10, pregunta: "¿Cuál es tu sueldo deseable?", Respuesta: "" },
        { id: 11, pregunta: "¿Cuál es tu lugar de residencia?", Respuesta: "" },
        {
          id: 12,
          pregunta:
            "¿Alguna vez has trabajado en algúna compañía o has sido distibuidor en el giro ferretero?",
          Respuesta: "",
        },
        {
          id: 13,
          pregunta:
            "¿Tienes familiares que sean dueños o trabajen en algún negocio o compañía del giro ferretero?",
          Respuesta: "",
        },
        {
          id: 14,
          pregunta: "¿Cuál es tu disponibilidad para entrevista?",
          Respuesta: "",
        },
      ],
      rules: [(v) => !!v || "Campo requerido."],
      preguntasClave: [],
      detalleVacante: {},
      apego: null,
    };
  },
  computed: {
    isSmallScreen() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name)
        ? true
        : false;
    },
  },
  async mounted() {
    this.detalleVacante = await this.getDetalleVacante().catch((e) => {
      alert(e);
    });
    this.showDialogWarningTerms = true;
    this.apego = await this.getApego().catch((e) => {
      alert(e);
    });
    this.preguntasClave = await this.getPreguntasClave().catch((e) => {
      alert(e);
    });
    // console.log(this.$route.params.type)
    if (this.$route.params.type === "1") {
      this.preguntas = [...this.preguntasBase, ...this.preguntasClave];
    } else {
      this.limiteSuperior = 5;
      this.preguntas = this.preguntasClave;
    }
  },
  methods: {
    showSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async getDetalleVacante() {
      let params = {
        Opcion: 1,
        token: this.$route.params.key,
        idVacante: this.$route.params.id,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos-confidenciales.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                resolve(response.data.detalleVacante);
              } else {
                resolve({});
              }
            } else {
              resolve({});
            }
          })
          .catch((e) => {
            reject(e);
          });
      }).finally(() => {
        this.loadingVacante = false;
      });
    },
    async getApego() {
      let params = {
        Opcion: 2,
        token: this.$route.params.key,
        idVacante: this.$route.params.id,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos-confidenciales.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                resolve(response.data.apego);
              } else {
                resolve({});
              }
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    async getPreguntasClave() {
      let params = {
        Opcion: 3,
        token: this.$route.params.key,
        idVacante: this.$route.params.id,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos-confidenciales.php`,
            params
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                let preguntasAux = [];
                response.data.preguntas.map((p) => {
                  preguntasAux.push({
                    id: p.id,
                    pregunta: p.pregunta,
                    Respuesta: "",
                  });
                });

                resolve(preguntasAux);
              } else {
                resolve({});
              }
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    saveResponses() {
      if (this.$refs.form.validate()) {
        const params = {
          Opcion: 4,
          token: this.$route.params.key,
          preguntas: this.preguntas,
          idVacante: this.$route.params.id,
          tipo: this.$route.params.type,
        };
        console.log(this.preguntas);
        axios
          .post(
            `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos-confidenciales.php`,
            params
          )
          .then(async (response) => {
            if (response.data.status != "EXPSESSION") {
              if (response.data.status == "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.showSnackBar(
                    "teal darken-1",
                    response.data.respuesta.message
                  );
                  this.detalleVacante = await this.getDetalleVacante().catch(
                    (e) => {
                      alert(e);
                    }
                  );
                } else {
                  this.showSnackBar(
                    "pink darken-3",
                    response.data.respuesta.message
                  );
                }
              } else {
                this.showSnackBar("pink darken-3", "Ocurrió un error.");
              }
            }
          })
          .catch((e) => {
            this.showSnackBar("pink darken-3", e);
          })
          .finally(() => {
            this.loadingVacante = false;
          });
      }
    },
    nextQuestion() {
      if (this.$refs.form.validate()) {
        this.$refs.form.resetValidation();
        this.limiteInferior += 6;
        this.limiteSuperior += 6;
        if (this.limiteSuperior > this.preguntas.length) {
          this.limiteSuperior = this.preguntas.length;
        }
      }
    },
  },
};
</script>
