<template>
  <div>
    <v-toolbar color="primary" class="text-center">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        max-width="250"
      ></v-img>
    </v-toolbar>
    <v-container class="center-item-in-div" style="height: 60vh;">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col :class="colorTexto" class="subtitle-1 text-center font-weight-bold" cols="12">
          {{ mensaje }}
        </v-col>
        <v-col v-show="loadingData" cols="6" class="center-item-in-div">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "autoriza-candidato-Interno",
  components: {},
  data() {
    return {
      mensaje: "Validando token...",
      colorTexto: "black--text",
      loadingData: true,
    };
  },
  mounted(){
    this.autorizaCandidatoInterno()
  },
  methods: {
    autorizaCandidatoInterno() {
      let params = {
        Opcion: 10,
        token: this.$route.params.key,
        idCandidato: this.$route.params.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Reclutamiento/ws-admin-candidatos.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.candidato.status == "OK") {
              this.mensaje = response.data.candidato.message;
              this.colorTexto = "teal--text text--darken-1";
            } else {
              this.mensaje = response.data.candidato.message;
              this.colorTexto = "pink--text text--darken-3";
            }
          } else {
            this.mensaje = "Token Invalido.";
            this.colorTexto = "pink--text text--darken-3";
          }
        })
        .catch((e) => {
          this.mensaje = e;
          this.colorTexto = "pink--text text--darken-3";
        });
    },
  },
};
</script>
