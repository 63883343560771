<template>
  <v-dialog v-model="dialog" scrollable max-width="500px" persistent>
    <template>
      <v-card>
        <v-toolbar color="primary" dark>
          <v-row>
            <v-col cols="3" class="pt-7">
            <v-img
            :src="require('@/assets/img/chat.png')"
            height="41px"
            width="40px"
            ></v-img>
          </v-col>
            <v-col cols="9" style="margin-top: 5px">
              <h3>¡Hola! </h3>
              <p>Chatea con nosotros</p>
              
            </v-col>
          </v-row>
          <v-spacer> </v-spacer>
              <v-btn text dark @click="$emit('cierraAlu')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- <div id="webchat" style="overflow:auto;" role="main"></div> -->
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "persona-alu-externo",
  //   components: {},
  data: () => ({}),
  components: {},
  props: ["dialog"],
  mounted() {
    // this.loadALU();
  },
  methods: {
    loadALU() {
      window.WebChat.renderWebChat(
        {
          directLine: window.WebChat.createDirectLine({
            // token: "EpLx8nVM9-k.MMIB42XdmUoqH0AzJUsQVGqwo42tIIqwCBHJCZYELYA",
            token: 'kK5ncuXYyDs.fMuU-iySjEdHq8IzoXCDkVPHZqQ5TJb0XW5cG1METe0'
            // token: "-CgAToik_7k.xCBOlKxrUojqLoguFuncO6M_nHIDUJBfbYJnLbAeRBo",
          }) /* PRODUCCIÓN */,
          // userID: user,
          // username: user,
          locale: "es-MX",
        },
        document.getElementById("webchat")
      );
    },
  },
};
</script>