<template>
  <!------------------------------------------------ TEMPLATE -->
  <v-dialog v-model="dialogVisible" scrollable max-width="400" content-class="fix-border-dialog">
    <v-card :loading="loadingRutas" class="rounded-lg">
      <v-card-title class="primary white--text subtitle-2">
        Rutas de trasporte
        <v-spacer></v-spacer>
        <v-icon right dark @click="$emit('cierraTransporte')"> mdi-close </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-list nav dense>
          <v-list-item-group color="primary">
            <v-list-item v-for="(ruta, i) in rutas" :key="i" @click="pdfRuta(ruta.DescRuta)">
              <v-list-item-icon>
                <v-icon>mdi-bus-stop</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="ruta.DescRuta"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <!-- <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text >
          Cerrar
        </v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
// ---------------------------------------------- CONTROLLER
import axios from 'axios'
// import { METHODS } from 'http';
export default {
  name: 'general-transporte-dialog',
  data() {
    return {
      visible: false,
      loadingRutas: true,
      rutas: [],
    }
  },
  props: ['dialogVisible'],
  mounted() {
    this.getDataRouts();
  },
  methods: {
    getDataRouts() {
      let params = {
        Opcion: 1
      }
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-rutas.php`, params)
        //.get('http://localhost:3080/api/rutas')
        .then((result) => {
          this.rutas = result.data.rutas;
          //(result.data.rutas[0])
          this.loadingRutas = false;
        });
    },
    pdfRuta(nombre) {
      let params = {
        nombre: nombre
      }
      axios
        // .post(`${this.$store.getters.getHost}/Login/ws-rutas.php`, params)
        .post(`${this.$store.getters.getHostNode}/api/pdfruta`, params, {
         responseType: 'arraybuffer'
        })
        .then((response) => {
          // Crear un blob a partir de los datos recibidos
          const blob = new Blob([response.data], { type: 'application/pdf' });

          // Crear un objeto URL para el blob
          const url = window.URL.createObjectURL(blob);

          // Crear un enlace temporal para descargar el archivo
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nombre + '.pdf');

          // Agregar el enlace al DOM y hacer clic en él para iniciar la descarga
          document.body.appendChild(link);
          link.click();

          // Limpiar el objeto URL y eliminar el enlace del DOM
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        });
    }
  }
}
</script>

<style lang="sass"> // ---------------------------------------------- STYLES
</style>
