<template>
  <v-dialog
    v-model="verAluExterno"
    :fullscreen="$vuetify.breakpoint.name == 'xs' ? true : false"
    max-width="400"
    persistent
    position="bottom right"
    content-class="fix-border-dialog-alu"
  >
    <v-card class="rounded-xl rounded-br-0" max-height="950" min-height="550">
      <v-toolbar color="primary" dark>
        <v-row>
          <v-col cols="3" class="pt-7">
            <v-img
            :src="require('@/assets/img/chat.png')"
            height="41px"
            width="40px"
            ></v-img>
          </v-col>
          <v-col cols="9" style="margin-top: 5px">
            <h3>¡Hola!</h3>
            <p>Chatea con nosotros</p>
          </v-col>
        </v-row>
        <v-spacer> </v-spacer>
        <v-btn text dark @click="$emit('cierraAlu')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text ref="chatArea" class="containerChat pt-2">
        <v-sheet v-if="loadingData">
          <v-row
            class="fill-height py-5"
            align-content="center"
            justify="center"
          >
            <v-col class="subtitle-1 text-center" cols="12">
              {{ mensajeConcatenado }}
            </v-col>
            <v-col cols="6" class="center-item-in-div">
              <v-progress-circular
                :size="70"
                :width="8"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-sheet>
        <div
          v-else
          v-for="(message, index) in messages"
          :key="index"
          class="content"
          ref="contentRef"
        >
          <div
            class="px-4 pb-1"
            v-if="message.type == 1"
            style="display:flex; justify-content: left;"
          >
            <v-card
              class="elevation-0 rounded-xl rounded-bl-0 "
              color="blue-grey lighten-5"
              min-width="40%"
            >
              <v-card-title class="font-weight-bold pt-1 pb-0 subtitle-2">
                {{ message.title }}
              </v-card-title>
              <v-card-text class="pb-2 pt-0">
                {{ message.message }}
              </v-card-text>
              <div class="pb-2 px-2" v-if="message.haveOptions">
                <v-btn
                  v-for="(button, index) in message.options"
                  :key="index"
                  small
                  block
                  class="mr-1 elevation-0 rounded-pill font-weight-bold mb-1 mi-boton"
                  style="max-width: 100%;"
                  color="white"
                  :disabled="message.disabled"
                  @click="
                    (event) =>
                      button.onClick({
                        type: 2,
                        message: button.text,
                        haveOptions: false,
                        options: null,
                        item: message,
                      })
                  "
                >
                  <span
                    class="d-inline-block text-truncate text-decoration-underline blue-grey--text text--darken-1"
                    style="max-width: 285px !important;"
                  >
                    {{ button.text }}
                  </span>
                </v-btn>
              </div>
            </v-card>
          </div>
          <div
            class="px-4 pb-1"
            v-else-if="message.type == 3"
            style="display:flex; justify-content: left;"
          >
            <v-card
              class="elevation-0 rounded-xl rounded-bl-0 "
              color="blue-grey lighten-5"
              min-width="40%"
            >
              <v-card-title class="font-weight-bold pt-1 pb-0 subtitle-2">
                {{ message.title }}
              </v-card-title>
              <v-card-text class="pb-2 pt-0">
                <!-- {{ message }} -->
                <div class="subtitle-1 primary--text">
                  {{ message.subtitle }}
                </div>
                <div class="caption blue-grey--text text--lighten-1">
                  {{ message.subtitle2 }}
                </div>
                <br />
                <div class="text-justify">
                  <strong>Conocimientos requeridos: </strong
                  >{{ message.message }}
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div
            class="px-4 pb-1"
            v-else-if="message.type == 2"
            style="display:flex; justify-content: right;"
          >
            <v-card
              class="elevation-0 rounded-xl rounded-br-0"
              color="primary"
              min-width="40%"
            >
              <v-card-title
                class="font-weight-bold py-1 subtitle-2 white--text"
              >
                {{ message.title }}
              </v-card-title>
              <v-card-text class="pb-2 bt-0 white--text">
                {{ message.message }}
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions
        class="pt-0 pb-1 pr-0"
        style="display: flex; justify-content: space-between;"
      >
        <v-text-field
          v-model="msgUser"
          placeholder="Escribe un mensaje..."
          :disabled="!preguntasAbiertas"
          @keyup.enter="sendMessage"
        ></v-text-field>
        <v-btn
          text
          fab
          @click="
            insertMessage(2, msgUser, false, null, null);
            msgUser = '';
          "
          :disabled="!preguntasAbiertas"
        >
          <v-icon color="primary"> mdi-send </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";
// import CryptoJS from "crypto-js";

export default {
  name: "persona-alu-externo2",
  props: ["verAluExterno"],
  data() {
    return {
      messages: [],
      areas: [],
      areasOptions: [],
      vacantes: [],
      user: {},
      userName: "",
      loadingMessage: "",
      msgUser: "",
      mensajes: [],
      preguntasBase: [
        { id: 1, pregunta: "¿Cuál es tu nombre completo?", respuesta: "" },
        { id: 2, pregunta: "Ingresa tu CURP", respuesta: "" },
        {
          id: 3,
          pregunta: "¿Cuál es tu último nivel de estudios?",
          respuesta: "",
        },
        { id: 4, pregunta: "Ingresa tu número de teléfono", respuesta: "" },
        {
          id: 5,
          pregunta: "Ingresa por favor tu dirección de correo electrónico",
          respuesta: "",
        },
        {
          id: 6,
          pregunta:
            "¿Cuál es el tiempo de traslado que te tomaría llegar a la empresa?",
          respuesta: "",
        },
        {
          id: 7,
          pregunta:
            "¿Cuál fue tu ultimo puesto y cual fue la permanencia en este?",
          respuesta: "",
        },
        {
          id: 8,
          pregunta:
            "¿Cuál fue tu penultimo puesto y cual fue la permanencia en este?",
          respuesta: "",
        },
        {
          id: 9,
          pregunta: "¿Cuál fue tu último sueldo percibido?",
          respuesta: "",
        },
        { id: 10, pregunta: "¿Cuál es tu sueldo deseable?", respuesta: "" },
        { id: 11, pregunta: "¿Cuál es tu lugar de residencia?", respuesta: "" },
        {
          id: 12,
          pregunta:
            "¿Alguna vez has trabajado en algúna compañía o has sido distibuidor en el giro ferretero?",
          respuesta: "",
        },
        {
          id: 13,
          pregunta:
            "¿Tienes familiares que sean dueños o trabajen en algún negocio o compañía del giro ferretero?",
          respuesta: "",
        },
        {
          id: 14,
          pregunta: "¿Cuál es tu disponibilidad para entrevista?",
          respuesta: "",
        },
      ],
      scrollInterval: null, // intervalo para desplazar el scroll hacia abajo
      loadingData: true,
      preguntasAbiertas: false,
      indexPreguntas: 0,
      idArea: 0,
      idVacante: 0,
      menuPrincipalOptions: [
        {
          text: "Postularme a una vacante.",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuCloseOptions: [
        {
          text: "Sí",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "No",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
      menuEndConversation: [
        {
          text: "Reiniciar conversación",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
        {
          text: "Terminar conversación",
          onClick: (params) =>
            this.insertMessage(
              params.type,
              params.message,
              params.haveOptions,
              params.options,
              params.item
            ),
        },
      ],
    };
  },
  computed: {
    mensajeConcatenado() {
      return this.mensajes.join(" ");
    },
  },
  mounted() {
    for (let i = 0; i < 3; i++) {
      setTimeout(() => {
        if (i == 0) {
          this.mensajes.push("Espera un poco...");
        } else if (i == 1) {
          this.mensajes.push("Un poquito más...");
        } else {
          if (i == 2) {
            this.mensajes.push(
              "Para llevarte mi felicidaaaaad..." +
                this.addEmoji(0x1f3b6) +
                this.addEmoji(0x1f3a4)
            );
          }
        }
      }, i * 500);
    }
    this.getAreas();
  },
  methods: {
    getAreas() {
      let params = {
        Opcion: 1,
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-alu.php`, params)
        .then((response) => {
          this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.areas = response.data.areas;
            this.areas.map((a) => {
              this.areasOptions.push({
                text: a.NomExterno,
                onClick: (params) =>
                  this.insertMessage(
                    params.type,
                    params.message,
                    params.haveOptions,
                    params.options,
                    params.item
                  ),
              });
            });
            this.insertMessage(1, "¡Hola!", false, null, null);
            this.insertMessage(
              1,
              "¿Qué deseas hacer hoy?",
              true,
              this.menuPrincipalOptions,
              null
            );
          }
        })
        .catch(function(e) {
          console.log(e);
        });
    },
    async insertMessage(
      type,
      message,
      haveOptions,
      options = null,
      item = null,
      subtitle = null,
      subtitle2 = null
    ) {
      if (item != null) {
        this.disabledButtons(item);
      }
      if (type === 1) {
        this.messages.push({
          type: 1,
          title: "ALU",
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
      } else if (type === 3) {
        this.messages.push({
          type: 3,
          title: "ALU",
          subtitle: subtitle,
          subtitle2: subtitle2,
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
      } else {
        this.messages.push({
          type: 2,
          title: this.userName,
          message: message,
          actions: {},
          haveOptions: haveOptions,
          options: options,
          disabled: false,
        });
        if (this.preguntasAbiertas) {
          await this.saveRespuesta(message);
          this.findMenuSelected(message);
        } else {
          this.findMenuSelected(message);
        }
      }
      this.$nextTick(() => {
        let messageDisplay = this.$refs.chatArea;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    findMenuSelected(message) {
      // let opcDictionnary = message.charAt(0);
      switch (message) {
        case "Postularme a una vacante.": {
          this.insertMessage(
            1,
            "¿En que área estas interesado o interesada?",
            true,
            this.areasOptions,
            null
          );
          break;
        }
        case "Desarrollo Humano":
        case "Recursos Humanos": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 1;
          this.getVacantes();
          break;
        }
        case "Administración y Finanzas": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 2;
          this.getVacantes();
          break;
        }
        case "Logística": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 6;
          this.getVacantes();
          break;
        }
        case "Manufactura": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 7;
          this.getVacantes();
          break;
        }
        case "Mercadotecnia": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 10;
          this.getVacantes();
          break;
        }
        case "Tecnología de la Información": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 11;
          this.getVacantes();
          break;
        }
        case "Ventas nacionales": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 9;
          this.getVacantes();
          break;
        }
        case "Ventas Internacional": {
          this.insertMessage(
            1,
            "Muy bien, dame un momento estoy buscando las vacantes que tenemos disponibles... " +
              this.addEmoji(0x1f9d0),
            false,
            null,
            null
          );
          this.idArea = 12;
          this.getVacantes();
          break;
        }
        case "Reiniciar conversación": {
          this.reloadConversation();
          break;
        }
        case "Terminar conversación": {
          this.endConversation();
          break;
        }
        case "no":
        case "NO":
        case "No":
        case "nO": {
          if (!this.preguntasAbiertas) {
            this.insertMessage(
              1,
              "¿Qué te gustaría hacer?" + this.addEmoji(0x1f47d),
              true,
              this.menuEndConversation
            );
          } else if (
            this.preguntasAbiertas &&
            this.indexPreguntas < this.preguntasBase.length
          ) {
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          } else if (
            this.preguntasAbiertas &&
            this.indexPreguntas == this.preguntasBase.length
          ) {
            this.preguntasAbiertas = false;
            this.insertMessage(
              1,
              "¡Casi listo! Ahora solo dame un momento para enviar tu información a nuestro equipo de reclutamiento... " +
                this.addEmoji(0x1f44c),
              false,
              null,
              null
            );
            this.saveCandidate();
          }
          break;
        }
        case "SÍ":
        case "Sí":
        case "Si":
        case "SI":
        case "si":
        case "sí": {
          if (!this.preguntasAbiertas) {
            this.insertMessage(
              1,
              "¡Excelente! Dame un momento, voy por las preguntas... " +
                this.addEmoji(0x1f6b6),
              false,
              null,
              null
            );
            this.preguntasAbiertas = true;
            this.getPreguntas();
          } else if (
            this.preguntasAbiertas &&
            this.indexPreguntas < this.preguntasBase.length
          ) {
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          } else if (
            this.preguntasAbiertas &&
            this.indexPreguntas == this.preguntasBase.length
          ) {
            this.preguntasAbiertas = false;
            this.insertMessage(
              1,
              "¡Casi listo! Ahora solo dame un momento para enviar tu información a nuestro equipo de reclutamiento... " +
                this.addEmoji(0x1f44c),
              false,
              null,
              null
            );
            this.saveCandidate();
          }
          break;
        }
        default: {
          let filterVacante = this.vacantes.filter((v) => {
            return v.Nombre === message;
          });

          if (filterVacante.length > 0 && !this.preguntasAbiertas) {
            this.idVacante = filterVacante[0].id;
            let conocmimientos = filterVacante[0].app.conocimientos
              .map((obj) => obj.descr)
              .join(", ");
            this.insertMessage(
              3,
              conocmimientos,
              false,
              null,
              null,
              filterVacante[0].Nombre,
              filterVacante[0].app.escolaridad.carrera
            );
            this.insertMessage(
              1,
              "¿Te gustaría postularte?",
              true,
              this.menuCloseOptions
            );
          } else if (
            this.preguntasAbiertas &&
            this.indexPreguntas < this.preguntasBase.length
          ) {
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          } else if (
            this.preguntasAbiertas &&
            this.indexPreguntas == this.preguntasBase.length
          ) {
            this.preguntasAbiertas = false;
            this.insertMessage(
              1,
              "¡Casi listo! Ahora solo dame un momento para enviar tu información a nuestro equipo de reclutamiento... " +
                this.addEmoji(0x1f44c),
              false,
              null,
              null
            );
            this.saveCandidate();
          } else {
            this.insertMessage(
              1,
              "Lo siento, no puedo entenderte. " + this.addEmoji(0x1f915),
              false,
              null,
              null
            );
            this.insertMessage(
              1,
              "¿Qué deseas hacer hoy?",
              true,
              this.menuPrincipalOptions,
              null
            );
          }
          break;
        }
      }
    },
    saveRespuesta(message) {
      return new Promise((resolve) => {
        this.preguntasBase[this.indexPreguntas].respuesta = message;
        // console.log(this.preguntasBase[this.indexPreguntas]);
        this.indexPreguntas++;

        resolve();
      });
    },
    disabledButtons(item) {
      let index = this.messages.indexOf(item);
      this.messages[index].disabled = true;
    },
    capitalizeString(str) {
      return str
        .toLowerCase()
        .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    addEmoji(codigo) {
      return String.fromCodePoint(codigo);
    },
    getVacantes() {
      let params = {
        Opcion: 2,
        area: this.idArea,
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-alu.php`, params)
        .then((response) => {
          // this.loadingData = false;
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            let vacantesToShow = [];
            this.vacantes = response.data.vacantes;
            const promises = response.data.vacantes.map((a) => {
              return new Promise((resolve) => {
                const vacante = {
                  text: a.Nombre,
                  onClick: (params) =>
                    this.insertMessage(
                      params.type,
                      params.message,
                      params.haveOptions,
                      params.options,
                      params.item
                    ),
                };
                resolve(vacante);
              });
            });

            Promise.all(promises).then((vacantes) => {
              vacantesToShow = vacantes;
              this.insertMessage(
                1,
                "Estas son las vacantes disponibles. Si te interesa alguna da clic sobre el nombre. " +
                  this.addEmoji(0x1f642),
                true,
                vacantesToShow,
                null
              );
            });
          }
        })
        .catch(function(e) {
          console.log(e);
        });
    },
    getPreguntas() {
      let params = {
        Opcion: 3,
        idVacante: this.idVacante,
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-alu.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            response.data.preguntas.map((p) => {
              this.preguntasBase.push({
                id: p.id,
                pregunta: p.pregunta,
                respuesta: "",
              });
            });
            this.insertMessage(
              1,
              this.preguntasBase[this.indexPreguntas].pregunta,
              false,
              null,
              null
            );
          }
        });
    },
    sendMessage() {
      if (this.msgUser != "") {
        this.insertMessage(2, this.msgUser, false, null, null);
        this.msgUser = "";
      }
    },
    saveCandidate() {
      const params = {
        Opcion: 4,
        idVacante: this.idVacante,
        datos: JSON.stringify(this.preguntasBase),
      };
      axios
        .post(`${this.$store.getters.getHost}/Login/ws-alu.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.insertMessage(
                1,
                "¡Listo! Tu información se guardo correctamente. Espera una pronta respuesta de nuestro departamento de reclutamiento" +
                  this.addEmoji(0x1f389) +
                  this.addEmoji(0x1f389) +
                  this.addEmoji(0x1f389) +
                  this.addEmoji(0x1f389),
                true,
                this.menuEndConversation
              );
            } else {
              this.insertMessage(
                1,
                "¡Oh no! Tu información no pudo guardarse. Si el problema persiste puedes contactar a reclutamiento@urrea.net. " +
                  this.addEmoji(0x1f625),
                true,
                this.menuEndConversation
              );
            }
          } else {
            this.insertMessage(
              1,
              "¡Oh no! Tu información no pudo guardarse. Si el problema persiste puedes contactar a reclutamiento@urrea.net. " +
                this.addEmoji(0x1f625),
              true,
              this.menuEndConversation
            );
          }
        })
        .catch((e) => {
          this.insertMessage(1, e + this.addEmoji(0x1f916), false, null);
        });
    },
    reloadConversation() {
      if(this.preguntasBase.length > 14){
        this.preguntasBase.splice(-5);
      }
      this.messages.length = 0;
      this.areas.length = 0;
      this.areasOptions.length = 0;
      this.loadingData = true;
      this.getAreas();
    },
    endConversation() {
      this.reloadConversation();
      this.$emit("cierraAlu");
    },
  },
};
</script>

<style scoped>
.containerChat {
  background: white;
  height: 65vh;
  overflow: auto;
}

.v-dialog__content {
  align-items: end !important;
  display: flex;
  height: 100%;
  justify-content: end !important;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 100%;
  z-index: 6;
  outline: none;
}

.mi-boton .v-btn__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .containerChat {
    background: white;
    height: 85vh;
    overflow: auto;
  }
}
</style>
