<template>
  <div>
    <v-toolbar color="primary" class="text-center">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        max-width="250"
      ></v-img>
    </v-toolbar>
    <v-container style="height: 60vh;" fluid>
      <div v-if="loadingData" class="center-item-in-div-column">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="center-item-in-div pt-10">
        <div v-if="muestraTextBoxRechazo" style="width: 500px;">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-textarea
              v-model="comentarioRechazo"
              placeholder="Justifica el rechazo..."
              class="rounded-lg"
              outlined
              dense
              rows="3"
              required
              :rules="rules"
              counter="500"
            >
            </v-textarea>
          </v-form>
          <v-btn
            depressed
            dense
            color="pink darken-3"
            class="rounded-lg"
            block
            dark
            @click="loadingData = true; rechazarOferta()"
          >
            Rechazar
          </v-btn>
        </div>
        <div
          :class="colorTexto"
          v-else
          class="subtitle-1 text-center font-weight-bold"
        >
          {{ mensaje }}
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "autoriza-oferta-economica",
  components: {},
  data() {
    return {
      mensaje: "Validando token...",
      colorTexto: "black--text",
      comentarioRechazo: "",
      loadingData: true,
      muestraTextBoxRechazo: false,
      valid: true,
      rules: [
        v => !!v || 'El comentario es requerido.',
        v => (v && v.length <= 500) || 'El comentario debe tener menos de 500 caracteres.',
      ],
    };
  },
  mounted() {
    if (this.$route.params.tipo == 1) {
      this.autorizaOfertaEconomica();
    } else {
      this.muestraTextBoxRechazo = true;
      this.mensaje = "";
      this.loadingData = false;
    }
  },
  methods: {
    autorizaOfertaEconomica() {
      let params = {
        Opcion: 17,
        token_oferta: this.$route.params.key,
        idOferta: this.$route.params.id,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta.estatus_tran == "OK") {
              this.mensaje = "Oferta autorizada con éxito.";
              this.colorTexto = "teal--text text--darken-1";
            } else {
              this.mensaje = "No se pudo autorizar debido a un error de sistema: " + response.data.respuesta.desc;
              this.colorTexto = "pink--text text--darken-3";
            }
          } else {
            this.mensaje = "La oferta no se autorizó debido a que el token a expirado. Esto puede deberse a que se había autorizado con anterioridad.";
            this.colorTexto = "pink--text text--darken-3";
          }
        })
        .catch((e) => {
          this.mensaje = "Error de sistema: " + e;
          this.colorTexto = "pink--text text--darken-3";
        });
    },
    rechazarOferta(){
      if(this.$refs.form.validate()){
        let params = {
        Opcion: 18,
        token_oferta: this.$route.params.key,
        idOferta: this.$route.params.id,
        comentario: this.comentarioRechazo
      };
      axios
        .post(
          `${this.$store.getters.getHost}/Nominas/Compensaciones/ws-ofertasEconomicas.php`,
          params
        )
        .then((response) => {
          this.loadingData = false;
          this.muestraTextBoxRechazo = false;
          if (response.data.status != "EXPSESSION") {
            if (response.data.respuesta.estatus_tran == "OK") {
              this.mensaje = "Estatus actualizado con éxito.";
              this.colorTexto = "teal--text text--darken-1";
            } else {
              this.mensaje = "No se pudo actualizar el estatus debido a un error de sistema: " + response.data.respuesta.desc;
              this.colorTexto = "pink--text text--darken-3";
            }
          } else {
            this.mensaje = "La oferta no se rechazó debido a que el token a expirado. Esto puede deberse a que se había autorizado con anterioridad.";
            this.colorTexto = "pink--text text--darken-3";
          }
        })
        .catch((e) => {
          this.mensaje = "Error de sistema: " + e;
          this.colorTexto = "pink--text text--darken-3";
        });
      }
    }
  },
};
</script>
