<template>
  <div>
    <v-dialog v-model="muestraVista" scrollable fullscreen>
      <v-card class="rounded-0">
        <v-card-title color="primary" dense class="center-item-in-div primary py-0">
          <v-img :src="require('@/assets/icons/dh-logo.png')" max-height="60" max-width="200"></v-img>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="center-item-in-div">
            <v-sheet v-if="loadingTokenValidation" width="500" height="400" class="pa-5 center-item-in-div">
              <v-progress-circular :size="150" :width="10" color="primary" indeterminate>
                <label class="overline text-center font-weight-bold">validando <br />
                  token</label>
              </v-progress-circular>
            </v-sheet>
            <v-sheet v-else max-width="700px" class="pa-5">
              <div v-if="passe.estatus != 0">
                <v-card class="pa-5 center-item-in-div-column rounded-xl" max-width="500px">
                  <v-icon style="font-size: 5rem !important;" color="#A8A8A7">mdi-lock-off</v-icon>
                  <label class="overline font-italic text-center blue-grey--text text--dareken-2">
                    Este pase de ingreso ya fue autorizado por
                    <strong>{{ passe.nombreAutoriza }} </strong> el día
                    {{ passe.fecha_autorizacion ? "el dia " + fixDate(passe.fecha_autorizacion.date.substring(0, 10)) :
      "" }} , no es necesario que lo vuelva a autorizar
                    <!-- <strong>El token expiró.</strong> Esto se debe a que el
                    estatus ya fue modificado por
                    <strong>{{ passe.autoriza }}</strong>
                    {{
                      passe.fecha_autorizacion
                        ? "el dia " +
                          fixDate(
                            passe.fecha_autorizacion.date.substring(0, 10)
                          )
                        : ""
                    }}
                    por lo cual ya <strong>no es posible</strong> modificar
                    nuevamente. -->
                  </label>
                </v-card>
              </div>
              <div v-else>
                <div v-if="!passwordValidation">
                  <v-sheet max-width="400px" class="elevation-1 rounded-xl pa-5">
                    <v-form ref="formPassword">
                      <label class="caption font-italic blue-grey--text text-darken-1">
                        Por seguridad ingresa tu contraseña del portal DH
                      </label>
                      <v-text-field v-model="password" prepend-inner-icon="mdi-lock-outline" class="mt-3 rounded-lg"
                        outlined dense :label="$t('userPassword')" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass" required
                        :rules="rulesPassword">
                      </v-text-field>
                      <v-btn block class="rounded-lg font-weight-bold" dark large color="primary"
                        :loading="loadingBtnPsw" @click="
      loadingBtnPsw = true;
    validatePsw();
    ">
                        validar contraseña
                      </v-btn>
                    </v-form>
                  </v-sheet>
                </div>
                <div v-else>
                  <v-sheet max-width="700px" class="elevation-2 pa-5 mt-8">
                    <v-row dense>
                      <v-col :cols="isSmallScreen ? 12 : 7">
                        <v-text-field v-model="passe.nombre" class="rounded-lg" label="Nombre" outlined dense
                          style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 12 : 5">
                        <v-text-field :value="fixDate(passe.fecha_solicitud.date.substring(0, 10))" class="rounded-lg"
                          label="Fecha de solicitud" outlined dense style="max-height: 42px !important;"
                          disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 6 : 2">
                        <v-text-field :value="passe.numero_personal" class="rounded-lg" label="Número personal" outlined
                          dense style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 6 : 3">
                        <v-text-field :value="passe.proveedor" class="rounded-lg" label="Proveedor" outlined dense
                          style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 7 : 4">
                        <v-text-field :value="passe.domicilio" class="rounded-lg" label="Domicilio" outlined dense
                          style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 5 : 3">
                        <v-text-field :value="passe.telefono" class="rounded-lg" label="Teléfono" outlined dense
                          style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 6 : 6">
                        <v-text-field :value="passe.areaDesc" class="rounded-lg" label="Área" outlined dense
                          style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                      <v-col :cols="isSmallScreen ? 6 : 6">
                        <v-text-field :value="passe.razonDesc" class="rounded-lg" label="Razón" outlined dense
                          style="max-height: 42px !important;" disabled></v-text-field>
                      </v-col>
                    </v-row>

                    <v-simple-table dense class="mt-2">
                      <thead>
                        <tr class="primary">
                          <th class="overline white--text text-center">No. Serie</th>
                          <th class="overline white--text text-center">Cantidad</th>
                          <th class="overline white--text text-center" cols="2">descripción</th>
                          <th class="overline white--text text-center">orden Compra</th>
                          <th class="overline white--text text-center">No Factura</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in passe.material" :key="index">
                          <td style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                            <input v-model="item.codigo" type="text" style="width: 100%;" />
                          </td>
                          <td style="border: 0.7px solid #A8A8A7; max-width: 60px;">
                            <input v-model="item.cantidad" type="number" style="width: 100%;" />
                          </td>
                          <td style="border: 0.7px solid #A8A8A7;">
                            <input v-model="item.descripcion" type="text" style="width: 100%;" />
                          </td>
                          <td style="border: 0.7px solid #A8A8A7; max-width: 100px;">
                            <input v-model="item.orden_compra" type="text" style="width: 100%;" />
                          </td>
                          <td style="border: 0.7px solid #A8A8A7;">
                            <input v-model="item.factura" type="text" style="width: 100%;" />
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <!-- {{ passe }} -->
                  </v-sheet>

                  <v-row dense class="mt-2">
                    <v-col cols="6">
                      <v-btn class="elevation-2 rounded-xl " :loading="loadingBtnReject" block x-large dark
                        color="pink darken-3" @click="
      estatus = 2;
    question = '¿Realmente deseas rechazar este pase?';
    showConfirm = true;
    ">
                        Rechazar
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn class="elevation-2 rounded-xl " :loading="loadingBtnAuthorize" block x-large dark
                        color="teal darken-1" @click="
      estatus = 1;
    question = '¿Realmente deseas autorizar este pase?';
    showConfirm = true;
    ">
                        Autorizar
                      </v-btn>
                    </v-col>
                  </v-row>

                  <ConfirmDialogReclutamiento v-if="showConfirm" :showConfirm="showConfirm" :question="question"
                    @cierraConfirm="getActionStatus" />
                </div>
              </div>
              <!-- {{ passe }} -->
            </v-sheet>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { formatDate } from "../store/Funciones/funciones";
import ConfirmDialogReclutamiento from "../views/generated/AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
// import SnackBar from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "autoriza-visita",
  components: { ConfirmDialogReclutamiento },
  data() {
    return {
      loadingTokenValidation: true,
      passwordValidation: false,
      showPass: false,
      loadingBtnPsw: false,
      showConfirm: false,
      loadingBtnAuthorize: false,
      loadingBtnReject: false,
      muestraVista: true,
      estatus: 0,
      empleado: -1,
      question: "",
      password: "",
      rulesPassword: [(v) => !!v || "La contraseña es obligatoria."],
      passe: {},
      aux: ''
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
  },
  mounted() {
    this.getInfoPass();
  },
  methods: {
    getInfoPass() {
      let params = {
        Opcion: 8,
        token: this.$route.params.key,
        idPase: this.$route.params.idPase,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.passe = response.data.respuesta;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingTokenValidation = false;
        });
    },
    validatePsw() {
      if (this.$refs.formPassword.validate()) {
        let params = {
          Opcion: 9,
          token: this.$route.params.key,
          user: this.$route.params.mail,
          password: this.password,
          solicitante: this.passe.solicitante,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.empleado = response.data.respuesta;
              this.passwordValidation = true;
            } else {
              alert("Contraseña inválida.");
            }
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.loadingBtnPsw = false;
          });
      } else {
        this.loadingBtnPsw = false;
      }
    },

    fixDate(date) {
      return formatDate(date);
    },
    getActionStatus(response) {
      this.showConfirm = false;
      if (response) {
        if (this.estatus === 1) {
          this.loadingBtnAuthorize = true;
        } else if (this.estatus === 2) {
          this.loadingBtnReject = true;
        }
        this.updateStatus();
      }
    },
    updateStatus() {
      let params = {
        Opcion: 10,
        token: this.$route.params.key,
        estatus: this.estatus,
        idPase: this.$route.params.idPase,
        empleado: this.empleado,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-ingreso.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_trans == "OK") {
              this.loadingTokenValidation = true;
              this.passe = {};
              this.passe = this.getInfoPass();
              alert(response.data.respuesta.message);
            } else {
              alert(response.data.respuesta.message);
            }
          } else {
            alert("Ocurrió un error.");
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.loadingBtnAuthorize = false;
          this.loadingBtnReject = false;
        });
    },
  },
};
</script>
