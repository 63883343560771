import axios from 'axios'
import { formatDate } from "../../../store/Funciones/funciones"
// import SnackBar from '../../generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue'

export default {
    name: 'auth-vcantes',
    components: {},
    props: [],
    data() {
        return {
            showPass: false,
            invalidKey: false,
            loadingData: true,
            muestraBarra: false,
            loadingPass: false,
            colorBarra: "",
            textoBarra: "",
            psw: "",
            intentos: 0,
            limiteIntentos: 3,
            timeout: 2000,
            rules: [(v) => !!v || "Campo requerido"],
            detalleVacante: {}
        }
    },
    computed: {

    },
    async mounted() {
        this.detalleVacante = await this.getInfoVacante().catch((error) => { alert(error) })
    },
    methods: {
        getInfoVacante() {
            let params = {
                Opcion: 4,
                token: this.$route.params.key,
                id: this.$route.params.id
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
                        params
                    )
                    .then((response) => {
                        resolve(response.data.detalle)
                    })
                    .catch((error) => {
                        reject(error)
                    })
                    .finally(() => {
                        this.loadingData = false;
                    });
            })

        },
        setEstatusVacante() {
            let params = {
                Opcion: 27,
                token: this.$route.params.key,
                id: this.$route.params.id,
                estatus: this.detalleVacante.estatusId,
                psw: this.psw
            }
            axios
                .post(
                    `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status != "EXPSESSION") {
                        if (response.data.respuesta.estatusPass == 'AUTHORIZED') {
                            this.muestraSnackBar("Módificado con éxito", "success");
                            window.location.reload()
                        } else {
                            this.intentos++;
                            if (this.intentos === 30) {
                                this.muestraSnackBar("Contraseña incorrecta, favor de validar. ", "error");
                                this.loadingPass = true;
                                this.invalidarToken()
                            } else {
                                this.muestraSnackBar("Contraseña incorrecta, favor de validar ", "error");
                            }
                        }
                    } else {
                        this.muestraSnackBar("ERROR. No se pudo validar la información", "error");
                    }
                }).catch((e) => {
                    this.muestraSnackBar(e, "error");
                }).finally(() => {
                    this.loadingPass = false;
                });
        },
        muestraSnackBar(texto, color) {
            this.muestraBarra = true;
            this.textoBarra = texto;
            this.colorBarra = color;
        },
        invalidarToken() {
            let params = {
                Opcion: 29,
                token: this.$route.params.key,
                id: this.$route.params.id,
            }
            axios
                .post(
                    `${this.$store.getters.getHost}/Reclutamiento/ws-admin-reclu.php`,
                    params
                )
                .then((response) => {
                    this.loadingPass = false
                    if (response.data.status != "EXPSESSION") {
                        this.invalidKey = true;
                        if (response.data.respuesta.status == 'OK') {
                            this.muestraSnackBar(response.data.respuesta, "primary");
                        } else {
                            this.muestraSnackBar(response.data.respuesta, "pink darken-3");
                        }
                    } else {
                        this.muestraSnackBar("ERROR. No se pudo validar", "pink darken-3");
                    }
                }).catch((e) => {
                    this.muestraSnackBar(e, "pink darken-3");
                });
        },
        fixFormatDate(date) {
            return formatDate(date)
        }
    }
}