<template>
  <div>
    <v-toolbar color="primary" class="center-item-in-div">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        max-width="250"
      ></v-img>
    </v-toolbar>
    <v-container class="center-item-in-div">
      <v-sheet
        v-if="loadingDetalleVisita"
        width="500"
        height="400"
        class="pa-5 center-item-in-div"
      >
        <v-progress-circular
          :size="150"
          :width="10"
          color="primary"
          indeterminate
        >
          <label class="overline text-center"
            >validando <br />
            token...</label
          >
        </v-progress-circular>
      </v-sheet>
      <v-sheet
        v-else-if="!loadingDetalleVisita && expression"
        width="500"
        height="400"
        class="pa-5 center-item-in-div-column"
      >
        <p class="text-center overline font-italic">
          {{ msjError }}
        </p>
        <v-card
          class="mb-3 rounded-lg elevation-0 px-5 py-2 text-center"
          color="blue-grey lighten-5"
          v-if="detalleVisita"
        >
          <v-simple-table
            dense
            style="background-color: transparent; border: none !important;"
          >
            <tbody>
              <tr>
                <th
                  style="border-bottom-color: #BDBDBD;"
                  colspan="2"
                  class="font-weight-black text-center subtitle-1 blue-grey--text text--darken-1"
                >
                  Información de la visita registrada
                </th>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  FOLIO DE VISITA:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.id }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Tipo de visita:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.descr }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Ubicación:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.Ubicacion }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Visitante:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{
                    detalleVisita.Nombre +
                      " " +
                      detalleVisita.Paterno +
                      " " +
                      detalleVisita.Materno
                  }}
                </td>
              </tr>
              <tr v-if="detalleVisita.id_tipo === 3">
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Compañía:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.Compania }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Solicitante:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{
                    "[" + detalleVisita.Emplid + "] " + detalleVisita.EMP_NOMEMP
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-sheet>
      <v-sheet v-else width="500" height="400" class="pa-5">
        <v-card
          class="mb-3 rounded-lg elevation-0 px-5 py-2 text-center"
          color="blue-grey lighten-5"
        >
          <v-simple-table
            dense
            style="background-color: transparent; border: none !important;"
          >
            <tbody>
              <tr>
                <th
                  style="border-bottom-color: #BDBDBD;"
                  colspan="2"
                  class="font-weight-black text-center subtitle-1 blue-grey--text text--darken-1"
                >
                  Información de la visita registrada
                </th>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  FOLIO DE VISITA:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.id }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Tipo de visita:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.descr }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Ubicación:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.Ubicacion }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Visitante:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{
                    detalleVisita.Nombre +
                      " " +
                      detalleVisita.Paterno +
                      " " +
                      detalleVisita.Materno
                  }}
                </td>
              </tr>
              <tr v-if="detalleVisita.id_tipo === 3">
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Compañía:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{ detalleVisita.Compania }}
                </td>
              </tr>
              <tr>
                <th
                  style="border-bottom-color: #E0E0E0;"
                  class="text-right font-weight-bold text-uppercase"
                >
                  Solicitante:
                </th>
                <td style="border-bottom-color: #E0E0E0;" class="text-left">
                  {{
                    "[" + detalleVisita.Emplid + "] " + detalleVisita.EMP_NOMEMP
                  }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>

        <v-form ref="formAutorizacion">
          <label class="font-italic caption blue-grey--text text--lighten-1">
            Ingresa la contraseña que usuas para ingresar al Portal DH
          </label>
          <v-text-field
            v-model="password"
            prepend-inner-icon="mdi-lock-outline"
            class="mt-3 rounded-lg"
            outlined
            dense
            :label="$t('userPassword')"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            @click:append="showPass = !showPass"
            required
            :rules="rulesPassword"
          >
          </v-text-field>
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                class="rounded-lg"
                color="pink darken-3"
                dark
                x-large
                @click="
                  seleccion = 2;
                  validaAccionAutorizador();
                "
              >
                Rechazar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                block
                class="rounded-lg"
                color="teal darken-1"
                dark
                x-large
                @click="
                  seleccion = 1;
                  validaAccionAutorizador();
                "
              >
                Autorizar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-container>

    <v-dialog
      v-model="comentarioRechazo"
      max-width="600"
      persitent
      content-class="fix-border-dialog"
    >
      <v-card class="rounded-xl">
        <v-card-title class="primary white--text pr-3">
          <div class="text-h6 font-weight-bold">Justifica la acción</div>
          <v-spacer></v-spacer>
          <v-icon dark @click="comentarioRechazo = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form ref="formComentarioRechazo">
            <v-textarea
              v-model="comentario"
              class="rounded-lg"
              outlined
              dense
              rows="2"
              placeholder="Escribe el motivo del rechazo..."
              :rules="commentRules"
              required
              counter="150"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            class="rounded-t-0 rounded-xl font-weight-bold teal lighten-5"
            x-large
            dark
            block
            text
            color="teal darken-1"
            @click="validaFormComentario"
          >
            guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import SnackBar from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "autoriza-visita",
  components: { SnackBar },
  data() {
    return {
      password: "",
      comentario: "",
      colorBarra: "",
      textoBarra: "",
      msjError:
        "El token ha expirado. Esto puede deberse a que la visita ya se autorizó o bien venció el tiempo de vida del token.",
      seleccion: 0,
      muestraBarra: false,
      showPass: false,
      loadingDetalleVisita: true,
      loadigBtnValidation: false,
      comentarioRechazo: false,
      expression: false,
      detalleVisita: {},
      rulesPassword: [(v) => !!v || "La contraseña es obligatoria."],
      commentRules: [
        (v) => !!v || "La motivo es obligatorio.",
        (v) =>
          (v && v.length <= 150) ||
          "El motivo debe tener menos de 150 caracteres.",
      ],
    };
  },
  async mounted() {
    this.detalleVisita = await this.getDetalleVisita().catch((e) => {
      alert(e);
    });
    if (("estatus" in this.detalleVisita)) {
      if(this.detalleVisita.estatus > 0){
        if (![undefined, null].includes(this.detalleVisita.Autoriza)) {
          let estatusAux = this.detalleVisita.estatus === 1 ? 'autorizó' : 'rechazó'
          this.msjError =
            "El estatus de la visita se " + estatusAux + " con éxito por: " +
            this.detalleVisita.nomAutoriza +
            (!['', null, undefined].includes(this.detalleVisita.fecha_modificacion) ? " el " + this.detalleVisita.fecha_modificacion : '') +
            ".";
          this.expression = true;
        }
      }
    } else {
      this.msjError =
          "Ocurrió un error. Solicita apoyo de soportetecnico@urrea.net con la clave " + this.$route.params.key;
        this.expression = true;
    }
  },

  methods: {
    async getDetalleVisita() {
      let params = {
        Opcion: 38,
        token_visita: this.$route.params.key,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.detalleVisita);
            } else if (response.data.status == "EXPSESSION") {
              this.expression = true;
              resolve({});
            }
          })
          .catch((error) => {
            reject([{ nombre: error }]);
          })
          .finally(() => {
            this.loadingDetalleVisita = false;
          });
      });
    },
    validaAccionAutorizador() {
      if (this.$refs.formAutorizacion.validate()) {
        if (this.seleccion === 1) {
          this.setEstatus();
        } else {
          this.comentarioRechazo = true;
        }
      }
    },
    validaFormComentario() {
      if (this.$refs.formComentarioRechazo.validate()) {
        this.comentarioRechazo = false;
        this.setEstatus();
      }
    },
    setEstatus() {
      this.loadigBtnValidation = true;
      const params = {
        Opcion: 39,
        token_visita: this.$route.params.key,
        idVisita: this.detalleVisita.id,
        estatus: this.seleccion,
        comentario: this.comentario,
        password: this.password,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status == "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.textoBarra = response.data.respuesta.message;
              this.colorBarra = "teal darken-1";
              this.muestraBarra = true;
              this.loadingDetalleVisita = true;
              window.location.reload()
              this.detalleVisita = await this.getDetalleVisita().catch((e) => {
                this.textoBarra = e;
                this.colorBarra = "pink darken-3";
                this.muestraBarra = true;
              });
            } else {
              this.textoBarra = response.data.respuesta.message;
              this.colorBarra = "pink darken-3";
              this.muestraBarra = true;
            }
          }
        })
        .catch((error) => {
          this.textoBarra = error;
          this.colorBarra = "pink darken-3";
          this.muestraBarra = true;
        })
        .finally(() => {
          this.loadigBtnValidation = false;
        });
    },
  },
};
</script>
