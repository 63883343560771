<template>
  <v-dialog v-model="dialogPrincipal" fullscreen scrollable>
    <v-card class="rounded-0">
      <v-card-title
        class="primary py-1"
        style="display: flex; justify-content: space-between; align-items: center;"
      >
        <v-img
          :src="require('@/assets/icons/dh-logo.png')"
          max-height="60"
          max-width="250"
        ></v-img>
        <v-btn
          color="light-blue darken-1"
          dark
          depressed
          class="rounded-lg"
          @click="showSendMail = true"
        >
          <v-icon :left="isSmallScreen ? false : true">
            mdi-clipboard-alert-outline
          </v-icon>
          <div v-show="!isSmallScreen">reportar problema</div>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container
          v-if="loadingData"
          class="center-item-in-div fondo-form"
          style="height: 60vh;"
        >
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col
              :class="colorTexto"
              class="subtitle-1 text-center font-weight-bold"
              cols="12"
            >
              {{ mensaje }}
            </v-col>
            <v-col cols="6" class="center-item-in-div">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>

        <v-container
          v-else-if="!loadingData && hideForm"
          class="center-item-in-div"
          style="height: 60vh;"
        >
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col
              :class="colorTexto"
              class="subtitle-1 text-center font-weight-bold overline"
              cols="12"
            >
              {{ visitante.error }}
            </v-col>
          </v-row>
        </v-container>

        <v-container
          fluid
          v-else
          :class="paddingPrincipal"
          class="fondo-form pt-0"
        >
          <div class="center-item-in-div-column py-2 mb-1">
            <v-card
              class="rounded-lg elevation-0 px-5 py-2 center-item-in-div-column"
            >
              <div class="subtitle-1 font-weight-bold text-uppercase">
                visitante
              </div>
              <div class="body-2 text-uppercase">
                {{
                  visitante.nombre +
                    " " +
                    visitante.apellido_paterno +
                    " " +
                    visitante.apellido_materno
                }}
              </div>
              <div class="subtitle-1 font-weight-bold text-uppercase">
                código de visita
              </div>
              <div class="body-2 text-uppercase">
                {{ this.$route.params.id }}
              </div>
            </v-card>
            <!-- {{visitante}} -->
            <v-alert
              v-if="visitante.descripcion != null"
              color="pink"
              class="rounded-lg mt-2"
              outlined
              border="top"
              icon="mdi-shield-alert"
            >
              <strong>¡ADVERTENCIA!</strong> Se reportó que realizarás <strong>"{{ visitante.descripcion }}"</strong> así que una vez
              hecho tu registro de entrada deberás acudir a <strong>servicio médico</strong>
              dentro de nuestras instalaciones para una rápida evaluación. 
            </v-alert>
          </div>
          <v-stepper
            v-model="registroDeMateriales"
            class="elevation-0 rounded-xl"
            vertical
          >
            <v-stepper-step step="1" :complete="registroArtPersonales">
              Articulos personales
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-card
                color="grey lighten-5"
                class="rounded-xl mb-12 elevation-0"
                min-height="200px"
              >
                <v-card-title
                  v-show="visitante.id_tipo === 3"
                  class="px-2 pb-0 pt-1"
                  style="display: flex; justify-content: end; align-items: center;"
                >
                  <v-btn
                    color="primary"
                    class="rounded-pill"
                    text
                    small
                    @click="registroDeMateriales++"
                  >
                    <div class="text-decoration-underline font-weight-bold">
                      Continuar
                    </div>
                    <v-icon right>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>
                  <v-alert
                    v-model="alertArtPersonales"
                    outlined
                    color="blue darken-1"
                    class="blue lighten-5 rounded-xl my-1"
                    style="border: 1px solid #1565C0 !important;"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        En está sección deberás ingresar los artículos
                        personales que ingresarás a las instalaciones, en caso
                        de que no vayas a ingresar artículos personales marca la
                        opción "No ingresaré artículos personales".
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon>
                          <v-icon
                            color="primary"
                            @click="alertArtPersonales = false"
                          >
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                  <div
                    style="display: flex; justify-content: start; align-items: center;"
                  >
                    <v-switch
                      v-model="switchArticulosPersonales"
                      inset
                      :disabled="articulosPersonales.length > 0 ? true : false"
                      v-on:change="openDialogWarnings(1)"
                      label="No ingresaré artículos personales"
                    ></v-switch>
                    <v-icon
                      color="cyan accent-3"
                      class="mx-2"
                      @click="openDialogWarnings(1)"
                    >
                      mdi-information-slab-circle-outline
                    </v-icon>
                  </div>
                  <v-row dense v-show="!switchArticulosPersonales">
                    <v-col :cols="numberColumForm">
                      <v-card class="rounded-xl pa-4 elevation-0">
                        <v-alert
                          outlined
                          v-model="alertFormArtPersonales"
                          dense
                          color="blue-grey darken-2"
                          class="blue-grey lighten-5 rounded-lg my-1"
                          style="border: 1px solid #546E7A !important;"
                        >
                          <v-row align="center">
                            <v-col class="grow">
                              Ingresa los datos de tu artículo.
                            </v-col>
                            <v-col class="shrink">
                              <v-btn icon>
                                <v-icon
                                  color="blue-grey darken-2"
                                  @click="alertFormArtPersonales = false"
                                >
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-alert>
                        <v-form
                          v-model="validFormArticulosPersonales"
                          ref="formArticulosPersonales"
                        >
                          <label class="overline">Artículo</label>
                          <v-text-field
                            v-model="articuloName"
                            dense
                            class="rounded-lg"
                            required
                            :rules="rulesTextField"
                            counter="50"
                            outlined
                          ></v-text-field>
                          <v-row dense>
                            <v-col cols="6">
                              <label class="overline">Marca</label>
                              <v-text-field
                                v-model="articuloMarca"
                                required
                                :rules="rulesTextField"
                                counter="50"
                                dense
                                class="rounded-lg"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <label class="overline">Cantidad</label>
                              <v-text-field
                                v-model="articuloCantidad"
                                required
                                :rules="rulesNumber"
                                dense
                                type="number"
                                class="rounded-lg"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <label class="overline">Descripción (breve)</label>
                          <v-text-field
                            v-model="articuloDesc"
                            required
                            :rules="rulesTextArea"
                            counter="100"
                            dense
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline"
                            >Número de serie (opcional)</label
                          >
                          <v-text-field
                            v-model="articuloNumSerie"
                            dense
                            counter="50"
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline"
                            >Adgunta evidencias (3mb máx.)</label
                          >
                          <v-file-input
                            v-model="imageArtPersonal"
                            :rules="fileRulesArtPersonal"
                            placeholder="Seleecciona una imagen..."
                            show-size
                            accept="image/*"
                            class="rounded-lg"
                            outlined
                            dense
                          ></v-file-input>
                        </v-form>
                        <v-btn
                          block
                          color="teal darken-1"
                          dark
                          large
                          depressed
                          class="rounded-lg"
                          :loading="loadingBtnArtsPersonales"
                          @click="
                            loadingBtnArtsPersonales = true;
                            pushInList(1);
                          "
                        >
                          <v-icon left> mdi-plus </v-icon>
                          <div>Añadir</div>
                        </v-btn>
                      </v-card>
                    </v-col>
                    <v-col :cols="numberColumTable">
                      <v-card
                        class="rounded-xl pa-4 elevation-0"
                        :loading="loadingArtsPersonales"
                      >
                        <v-simple-table dense>
                          <thead>
                            <tr>
                              <th>Artículo</th>
                              <th>Marca</th>
                              <th>Candidad</th>
                              <th>Número de serie</th>
                              <th class="text-center">Evidencia</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(articulo, index) in articulosPersonales"
                              :key="index"
                            >
                              <td>{{ articulo.nombre }}</td>
                              <td>{{ articulo.marca }}</td>
                              <td>{{ articulo.cantidad }}</td>
                              <td>{{ articulo.numSerie }}</td>
                              <td class="text-center">
                                <v-btn
                                  class="rounded-pill"
                                  v-if="articulo.filename !== null"
                                  small
                                  dark
                                  color="cyan darken-3"
                                  depressed
                                  @click="
                                    openFile(
                                      articulo.id,
                                      'arts_personales',
                                      articulo.filename
                                    )
                                  "
                                >
                                  <v-icon left small> mdi-file-image </v-icon>
                                  abrir
                                </v-btn>
                                <v-icon v-else color="grey lighten-1"
                                  >mdi-image-broken-variant</v-icon
                                >
                              </td>
                              <td>
                                <v-icon
                                  color="pink darken-3"
                                  @click="deleteArticuloPersonal(articulo)"
                                >
                                  mdi-close
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
              v-show="visitante.id_tipo === 3"
              step="2"
              :complete="registroConsumibles"
            >
              Consumibles
            </v-stepper-step>

            <v-stepper-content v-show="visitante.id_tipo === 3" step="2">
              <v-card
                color="grey lighten-5"
                class="rounded-xl mb-12 elevation-0"
                min-height="200px"
              >
                <v-card-title
                  class="px-2 pb-0 pt-1"
                  style="display: flex; justify-content: space-between; align-items: center;"
                >
                  <v-btn
                    color="primary"
                    class="rounded-pill"
                    text
                    small
                    @click="registroDeMateriales--"
                  >
                    <v-icon left>
                      mdi-chevron-left
                    </v-icon>
                    <div class="text-decoration-underline font-weight-bold">
                      Regresar
                    </div>
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="rounded-pill"
                    text
                    small
                    @click="
                      registroDeMateriales++;
                      warningMarcas = true;
                    "
                  >
                    <div class="text-decoration-underline font-weight-bold">
                      Continuar
                    </div>
                    <v-icon right>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    v-model="alertConsumibles"
                    outlined
                    color="blue darken-1"
                    class="blue lighten-5 rounded-xl my-1"
                    style="border: 1px solid #1565C0 !important;"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        En está sección deberás ingresar los artículos
                        consumibles que ingresarás a las instalaciones, en caso
                        de que no vayas a ingresar artículos consumibles marca
                        la opción "No ingresaré artículos consumibles".
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon>
                          <v-icon
                            color="primary"
                            @click="alertConsumibles = false"
                          >
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                  <div
                    style="display: flex; justify-content: start; align-items: center;"
                  >
                    <v-switch
                      v-model="switchConsumibles"
                      inset
                      :disabled="consumibles.length > 0 ? true : false"
                      v-on:change="openDialogWarnings(2)"
                      label="No ingresaré consumibles"
                    ></v-switch>
                    <v-icon
                      color="cyan accent-3"
                      class="mx-2"
                      @click="openDialogWarnings(2)"
                    >
                      mdi-information-slab-circle-outline
                    </v-icon>
                  </div>
                  <v-row dense v-show="!switchConsumibles">
                    <v-col :cols="numberColumForm">
                      <v-card class="rounded-xl pa-4 elevation-0">
                        <v-alert
                          outlined
                          v-model="alertFormConsumibles"
                          dense
                          color="blue-grey darken-2"
                          class="blue-grey lighten-5 rounded-lg my-1"
                          style="border: 1px solid #546E7A !important;"
                        >
                          <v-row align="center">
                            <v-col class="grow">
                              Ingresa los datos de tu artículo.
                            </v-col>
                            <v-col class="shrink">
                              <v-btn icon>
                                <v-icon
                                  color="blue-grey darken-2"
                                  @click="alertFormConsumibles = false"
                                >
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-alert>
                        <v-form
                          v-model="validConsumibles"
                          ref="formConsumibles"
                        >
                          <label class="overline">Artículo</label>
                          <v-text-field
                            v-model="consumibleName"
                            dense
                            class="rounded-lg"
                            required
                            :rules="rulesTextField"
                            counter="50"
                            outlined
                          ></v-text-field>
                          <v-row dense>
                            <v-col cols="6">
                              <label class="overline">Marca</label>
                              <v-text-field
                                v-model="consumibleMarca"
                                required
                                :rules="rulesTextField"
                                counter="50"
                                dense
                                class="rounded-lg"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <label class="overline">Cantidad</label>
                              <v-text-field
                                v-model="consumibleCantidad"
                                required
                                :rules="rulesNumber"
                                dense
                                type="number"
                                class="rounded-lg"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <label class="overline">Descripción (breve)</label>
                          <v-text-field
                            v-model="consumibleDesc"
                            required
                            :rules="rulesTextArea"
                            counter="100"
                            dense
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline"
                            >Número de serie (opcional)</label
                          >
                          <v-text-field
                            v-model="consumibleNumSerie"
                            dense
                            counter="50"
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline"
                            >Adgunta evidencias (3mb máx.)</label
                          >
                          <v-file-input
                            v-model="imageConsumibles"
                            :rules="fileRulesArtPersonal"
                            placeholder="Seleecciona una imagen..."
                            show-size
                            accept="image/*"
                            class="rounded-lg"
                            outlined
                            dense
                          ></v-file-input>
                        </v-form>
                        <v-btn
                          block
                          color="teal darken-1"
                          dark
                          large
                          depressed
                          class="rounded-lg"
                          @click="
                            loadingBtnConsumibles = true;
                            pushInList(2);
                          "
                        >
                          <v-icon left> mdi-plus </v-icon>
                          <div>Añadir</div>
                        </v-btn>
                      </v-card>
                    </v-col>
                    <v-col :cols="numberColumTable">
                      <v-card
                        class="rounded-xl pa-4 elevation-0"
                        :loading="loadingConsumibles"
                      >
                        <v-simple-table dense>
                          <thead>
                            <tr>
                              <th>Artículo</th>
                              <th>Marca</th>
                              <th>Candidad</th>
                              <th>Número de serie</th>
                              <th class="text-center">Evidencia</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(consumible, index) in consumibles"
                              :key="index"
                            >
                              <td>{{ consumible.nombre }}</td>
                              <td>{{ consumible.marca }}</td>
                              <td>{{ consumible.cantidad }}</td>
                              <td>{{ consumible.numSerie }}</td>
                              <td class="text-center">
                                <v-btn
                                  class="rounded-pill"
                                  v-if="consumible.filename !== null"
                                  small
                                  dark
                                  color="cyan darken-3"
                                  depressed
                                  @click="
                                    openFile(
                                      consumible.id,
                                      'consumibles',
                                      consumible.filename
                                    )
                                  "
                                >
                                  <v-icon left small> mdi-file-image </v-icon>
                                  abrir
                                </v-btn>
                                <v-icon v-else color="grey lighten-1"
                                  >mdi-image-broken-variant</v-icon
                                >
                              </td>
                              <td>
                                <v-icon
                                  color="pink darken-3"
                                  @click="deleteConsumible(consumible)"
                                >
                                  mdi-close
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-step v-show="visitante.id_tipo === 3" step="3">
              Herramientas
            </v-stepper-step>

            <v-stepper-content v-show="visitante.id_tipo === 3" step="3">
              <v-card
                color="grey lighten-5"
                class="rounded-xl mb-12 elevation-0"
                min-height="200px"
              >
                <v-card-title
                  class="px-1 pb-0 pt-1"
                  style="display: flex; justify-content: space-between; align-items: center;"
                >
                  <v-btn
                    color="primary"
                    class="rounded-pill"
                    text
                    small
                    @click="registroDeMateriales--"
                  >
                    <v-icon left>
                      mdi-chevron-left
                    </v-icon>
                    Regresar
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="rounded-pill"
                    text
                    small
                    @click="registroDeMateriales++"
                  >
                    Continuar
                    <v-icon right>
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    v-model="alertHerramientas"
                    outlined
                    color="blue darken-1"
                    class="blue lighten-5 rounded-xl my-1"
                    style="border: 1px solid #1565C0 !important;"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        En está sección deberás ingresar las herramientas que
                        ingresarás a las instalaciones, en caso de que no vayas
                        a ingresar herramientas marca la opción "No ingresaré
                        herramientas".
                        <strong
                          >¡IMPORTANTE! Obligatoriamente, mínimo el 80% de las
                          herramientas a ingresar deberán ser de las marcas de
                          GRUPO URREA.</strong
                        >
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon>
                          <v-icon
                            color="primary"
                            @click="alertHerramientas = false"
                          >
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                  <div
                    style="display: flex; justify-content: start; align-items: center;"
                  >
                    <v-switch
                      v-model="switchHerramientas"
                      inset
                      v-on:change="openDialogWarnings(3)"
                      label="No ingresaré herramientas"
                    ></v-switch>
                    <v-icon
                      color="cyan accent-3"
                      class="mx-2"
                      @click="openDialogWarnings(3)"
                    >
                      mdi-information-slab-circle-outline
                    </v-icon>
                  </div>
                  <v-row dense v-show="!switchHerramientas">
                    <v-col :cols="numberColumForm">
                      <v-card class="rounded-xl pa-4 elevation-0">
                        <v-form
                          v-model="validHerramientas"
                          ref="formHerramientas"
                        >
                          <v-alert
                            outlined
                            v-model="alertFormHerramientas"
                            dense
                            color="blue-grey darken-2"
                            class="blue-grey lighten-5 rounded-lg my-1"
                            style="border: 1px solid #546E7A !important;"
                          >
                            <v-row align="center">
                              <v-col class="grow">
                                Ingresa los datos de tu herramienta.
                              </v-col>
                              <v-col class="shrink">
                                <v-btn icon>
                                  <v-icon
                                    color="blue-grey darken-2"
                                    @click="alertFormHerramientas = false"
                                  >
                                    mdi-close
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-alert>
                          <label class="overline">Artículo</label>
                          <v-text-field
                            v-model="articuloHerramientaName"
                            dense
                            @input="getToolsCatalog()"
                            class="rounded-lg"
                            required
                            :loading="loadingCatalog"
                            placeholder="Busca por nombre..."
                            :rules="rulesTextArea"
                            counter="100"
                            outlined
                          ></v-text-field>
                          <v-card
                            v-show="
                              catalogoHerramientas.length > 0 &&
                                articuloHerramientaName.length > 2
                            "
                            class="rounded-lg px-2 blue-grey lighten-5 elevation-0"
                          >
                            <v-list class="rounded-xl transparent">
                              <v-list-item
                                v-for="(item, index) in catalogoHerramientas"
                                :key="index"
                                dense
                                class="body-2 text-uppercase font-weight-bold rounded-pill"
                                @click="
                                  articuloHerramientaName = item.NombreLargo;
                                  catalogoHerramientas.length = 0;
                                  setMarca(item.NombreLargo);
                                "
                              >
                                {{ item.NombreLargo }}
                              </v-list-item>
                            </v-list>
                          </v-card>

                          
                          <div
                            class="text-center caption font-italic blue-grey--text text-darken-1"
                          >
                            Porcentaje de herramientas de Grupo Urrea
                            {{ porcentajeHerramientasUrrea.toFixed(1) }}
                          </div>
                          <v-row dense>
                            <v-col cols="6">
                              <label class="overline">¿otra marca?</label>
                              <div class="center-item-in-div">
                                <v-switch
                                  v-model="otraMarca"
                                  class="pt-0 my-2"
                                  style="max-height: 40px;"
                                  :disabled="
                                    porcentajeHerramientasUrrea < 80 ||
                                    herramientas.length < 4
                                      ? true
                                      : false
                                  "
                                ></v-switch>
                              </div>
                            </v-col>
                            <v-col cols="6">
                              <label class="overline">marca</label>
                              <v-text-field
                                v-model="articuloHerramientaMarca"
                                class="rounded-lg"
                                dense
                                outlined
                                :disabled="!otraMarca ? true : false"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <div
                            v-if="
                              porcentajeHerramientasUrrea < 80 ||
                                herramientas.length < 4
                            "
                            class="text-center caption font-italic"
                          >
                            Para poder ingresar una herramienta de otra marca
                            deberás ingresar al menos 4 de las marcas de Grupo
                            Urrea.
                          </div>

                          <label class="overline">Cantidad</label>
                          <v-text-field
                            v-model="articuloHerramientaCantidad"
                            required
                            :rules="rulesNumber"
                            dense
                            type="number"
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline">Descripción (breve)</label>
                          <v-text-field
                            v-model="articuloHerramientaDesc"
                            required
                            :rules="rulesTextArea"
                            counter="100"
                            dense
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline"
                            >Número de serie (opcional)</label
                          >
                          <v-text-field
                            v-model="articuloHerramientaNumSerie"
                            dense
                            counter="50"
                            class="rounded-lg"
                            outlined
                          ></v-text-field>
                          <label class="overline"
                            >Adgunta evidencias (3mb máx.)</label
                          >
                          <v-file-input
                            v-model="imageHerramienta"
                            :rules="fileRulesArtPersonal"
                            placeholder="Seleecciona una imagen..."
                            show-size
                            accept="image/*"
                            class="rounded-lg"
                            outlined
                            dense
                          ></v-file-input>
                        </v-form>
                        <v-btn
                          block
                          color="teal darken-1"
                          dark
                          large
                          depressed
                          class="rounded-lg"
                          :loading="loadingBtnHerramientas"
                          @click="
                            loadingBtnHerramientas = true;
                            pushInList(3);
                          "
                        >
                          <v-icon left> mdi-plus </v-icon>
                          <div>Añadir</div>
                        </v-btn>
                      </v-card>
                    </v-col>
                    <v-col :cols="numberColumTable">
                      <v-card
                        class="rounded-xl pa-4 elevation-0"
                        :loading="loadingHerramientas"
                      >
                        <v-simple-table dense>
                          <thead>
                            <tr>
                              <th>Artículo</th>
                              <th>Marca</th>
                              <th>Candidad</th>
                              <th>Número de serie</th>
                              <th class="text-center">Evidencia</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(herramienta, index) in herramientas"
                              :key="index"
                            >
                              <td>{{ herramienta.nombre }}</td>
                              <td>{{ herramienta.marca }}</td>
                              <td>{{ herramienta.cantidad }}</td>
                              <td>{{ herramienta.numSerie }}</td>
                              <td class="text-center">
                                <v-btn
                                  class="rounded-pill"
                                  v-if="herramienta.filename !== null"
                                  small
                                  dark
                                  color="cyan darken-3"
                                  depressed
                                  @click="
                                    openFile(
                                      herramienta.id,
                                      'herramientas',
                                      herramienta.filename
                                    )
                                  "
                                >
                                  <v-icon left small> mdi-file-image </v-icon>
                                  abrir
                                </v-btn>
                                <v-icon v-else color="grey lighten-1"
                                  >mdi-image-broken-variant</v-icon
                                >
                              </td>
                              <td>
                                <v-icon
                                  color="pink darken-3"
                                  @click="deleteHerramienta(herramienta)"
                                >
                                  mdi-close
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
              v-show="
                visitante.id_tipo === 3 &&
                  [0, 1].includes(this.visitante.AccionVisitante)
              "
              step="4"
            >
              Vehículos
              <!-- <small>Alert message</small> -->
            </v-stepper-step>

            <v-stepper-content
              v-show="
                visitante.id_tipo === 3 &&
                  [0, 1].includes(this.visitante.AccionVisitante)
              "
              step="4"
            >
              <v-card
                color="grey lighten-5"
                class="rounded-xl mb-12 elevation-0"
                min-height="200px"
              >
                <v-card-title
                  class="px-1 pb-0 pt-1"
                  style="display: flex; justify-content: start; align-items: center;"
                >
                  <v-btn
                    color="primary"
                    class="rounded-pill"
                    text
                    small
                    @click="registroDeMateriales--"
                  >
                    <v-icon left>
                      mdi-chevron-left
                    </v-icon>
                    Regresar
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    v-model="alertVehiculo"
                    outlined
                    color="blue darken-1"
                    class="blue lighten-5 rounded-xl my-1"
                    style="border: 1px solid #1565C0 !important;"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        En está sección deberás ingresar los datos de tu
                        vehículo en caso de que no vayas a ingresar alguno
                        selecciona la opción "No ingresaré vehículo". Recuerda
                        que si dejas tu vehículo en el estacionamiento de
                        nuestras instalaciones no deberás llenar esta sección.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon>
                          <v-icon
                            color="primary"
                            @click="alertVehiculo = false"
                          >
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                  <div
                    style="display: flex; justify-content: start; align-items: center;"
                  >
                    <v-switch
                      v-model="switchVehiculo"
                      inset
                      v-on:change="dialogWarnings(4)"
                      label="No ingresaré vehículo"
                    ></v-switch>
                    <v-icon
                      color="cyan accent-3"
                      class="mx-2"
                      @click="openDialogWarnings(4)"
                    >
                      mdi-information-slab-circle-outline
                    </v-icon>
                  </div>

                  <v-alert
                    outlined
                    v-model="alertFormVehiculo"
                    dense
                    color="blue-grey darken-2"
                    class="blue-grey lighten-5 rounded-lg my-1"
                    style="border: 1px solid #546E7A !important;"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        Ingresa los datos de tu vehículo.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn icon>
                          <v-icon
                            color="blue-grey darken-2"
                            @click="alertFormVehiculo = false"
                          >
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                  <v-form v-model="validVehiculo" ref="formVehiculo">
                    <v-row dense v-show="!switchVehiculo">
                      <v-col :cols="12">
                        <v-card class="rounded-xl pa-4 elevation-0">
                          <v-row dense>
                            <v-col :cols="numberColumForm">
                              <label class="overline">Tipo de lecencia</label>
                              <v-select
                                v-model="tipoLincenciaSelected"
                                dense
                                class="rounded-lg"
                                outlined
                                :items="tiposLicencia"
                                item-text="DESCRIPCION"
                                value="ID"
                                placeholder="Selecciona el tipo de licencia..."
                              ></v-select>
                            </v-col>
                            <v-col :cols="numberColumForm">
                              <label class="overline">Número de licencia</label>
                              <v-text-field
                                v-model="numeroDeLicencia"
                                dense
                                class="rounded-lg"
                                outlined
                                placeholder="Ingresa el número de licencia..."
                              ></v-text-field>
                            </v-col>
                            <v-col :cols="numberColumForm">
                              <label class="overline">Tipo de vehículo</label>
                              <v-select
                                v-model="tipoDeVehiculoSelected"
                                dense
                                class="rounded-lg"
                                outlined
                                :items="tiposVehiculos"
                                item-text="DESCRIPCION"
                                value="ID"
                                placeholder="Selecciona el tipo de vehículo..."
                              ></v-select>
                            </v-col>
                            <v-col :cols="numberColumForm">
                              <label class="overline">Número económico</label>
                              <v-text-field
                                v-model="numeroEconomico"
                                dense
                                class="rounded-lg"
                                outlined
                                placeholder="Ingresa el número económico..."
                              ></v-text-field>
                            </v-col>
                            <v-col :cols="numberColumForm">
                              <label class="overline">Número de placas</label>
                              <v-text-field
                                v-model="numeroDePlacas"
                                dense
                                class="rounded-lg"
                                outlined
                                placeholder="Ingresa el número de placas..."
                              ></v-text-field>
                            </v-col>
                            <v-col :cols="numberColumForm">
                              <label class="overline"
                                >Poliza de seguro del vehículo</label
                              >
                              <v-text-field
                                dense
                                v-model="poliza"
                                class="rounded-lg"
                                outlined
                                placeholder="Ingresa la poliza de seguro..."
                              ></v-text-field>
                            </v-col>
                            <v-col :cols="numberColumForm">
                              <!-- <label class="overline"
                                >Responsable del GPS</label
                              >
                              <v-text-field
                                v-model="responsableGps"
                                dense
                                class="rounded-lg"
                                outlined
                                placeholder="Ingresa al responsable del GPS..."
                              ></v-text-field> -->
                              <label class="overline">Razón social</label>
                              <v-text-field
                                v-model="razonSocial"
                                dense
                                class="rounded-lg"
                                outlined
                                placeholder="Ingresa la razón social..."
                              ></v-text-field>
                            </v-col>
                            <v-col :cols="numberColumTable">
                              <label class="overline"
                                >Adgunta evidencias (3mb máx.)</label
                              >
                              <v-file-input
                                v-model="imageVehiculo"
                                :rules="fileRulesArtPersonal"
                                placeholder="Seleecciona una imagen..."
                                show-size
                                accept="image/*"
                                class="rounded-lg"
                                outlined
                                dense
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12"> </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <!-- <v-col :cols="numberColumForm">
                      <v-card class="rounded-xl pa-4 elevation-0">
                        <label class="overline">Número de caja</label>
                        <v-text-field
                          v-model="numeroDeCaja"
                          dense
                          class="rounded-lg"
                          outlined
                          placeholder="Ingresa el número de caja..."
                        ></v-text-field>
                        <label class="overline">Placas de caja</label>
                        <v-text-field
                          v-model="placasDeCaja"
                          dense
                          class="rounded-lg"
                          outlined
                          placeholder="Ingresa las placas de caja..."
                        ></v-text-field>
                        <label class="overline">Número de sello</label>
                        <v-text-field
                          v-model="numeroDeSello"
                          dense
                          class="rounded-lg"
                          outlined
                          placeholder="Ingresa el número de sello..."
                        ></v-text-field>
                      </v-card>
                    </v-col> -->
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-container>
      </v-card-text>
      <v-card-actions
        class="fondo-form"
        :class="isSmallScreen ? 'pa-0' : 'pa-3 center-item-in-div'"
      >
        <v-btn
          :block="isSmallScreen"
          large
          :disabled="!validaForms"
          :dark="validaForms"
          color="teal darken-1"
          class="fondo-form"
          @click="saveData"
          :class="isSmallScreen ? 'rounded-0' : 'rounded-lg elevation-0'"
        >
          <v-icon left>mdi-police-station</v-icon>
          Enviar información a caseta
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="dialogWarnings"
      persistent
      max-width="450"
      content-class="fix-border-dialog"
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="80"
            ></v-img>
          </div>
          <p
            class="subtitle-2 text-uppercase font-weight-bold text-center mb-3"
          >
            ¡Advertencia!
          </p>
          <div v-if="tipoAdvertencia === 1">
            <p class="body-2 mb-3">
              Recuerda que los artículos personales son todos aquellos objetos
              de uso personal que desees ingresar a la planta.
            </p>
            <br />
            Algunos ejemplos son:
            <ul class="body-2 mb-3">
              <li>
                Dispositivos electrónicos (laptops, celulares, tabletas, etc.).
              </li>
              <li>Cámaras.</li>
              <li>Etc.</li>
            </ul>
          </div>
          <div v-else-if="tipoAdvertencia === 2">
            <p class="body-2 mb-3">
              Recuerda que los artículos personales son el conjunto de
              materiales que se consumen para un servicio, instalación,
              reparación, mantenimiento relacionado a un trabajo o servicio
              especializado.
            </p>
            <br />
            Algunos ejemplos son:
            <ul class="body-2 mb-3">
              <li>
                Tornillos.
              </li>
              <li>Cables.</li>
              <li>Cintas.</li>
              <li>Charolas.</li>
              <li>Etc.</li>
            </ul>
          </div>
          <div v-else-if="tipoAdvertencia === 3">
            <p class="body-2 mb-3">
              Recuerda que la definición de herramientas es: instrumento,
              generalmente de hierro o acero, que sirve para hacer o reparar
              algo y que se usa con las manos.
            </p>
            <br />
            Algunos ejemplos son:
            <ul class="body-2 mb-3">
              <li>
                Herrmientas manuales (desarmadores, martillos, llaves).
              </li>
              <li>
                Herramientas de poder (Taladros, herramientas electicas, equipos
                eléctricos, etc.)
              </li>
              <li>Herramientas neumáticas.</li>
              <li>Equipo hidráulico o de carga.</li>
              <li>Etc.</li>
            </ul>
          </div>
          <center>
            <div class="text-center">
              <strong class="text-uppercase">Recuerda:</strong>
              Los materiales no registrados <strong>no podrán</strong>
              ingresar a la planta.
            </div>
          </center>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around;">
          <v-btn
            depressed
            class="rounded-pill"
            block
            dark
            color="teal darken-1"
            @click="
              dialogWarnings = false;
              validaCambioSwitchAriculosPersonales();
            "
            >de acuerdo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="warningMarcas"
      persistent
      max-width="400"
      content-class="fix-border-dialog"
    >
      <v-card class="neumorphism-card-reclutamiento">
        <v-card-text class="pt-5">
          <div style="display: flex; justify-content: center;">
            <v-img
              class="text-center"
              :src="require('@/assets/icons/advertencia.png')"
              max-width="80"
            ></v-img>
          </div>
          <p
            class="subtitle-2 text-uppercase font-weight-bold text-center mb-3"
          >
            ¡Advertencia!
          </p>
          <div class="body-2 text-justify">
            Es importante que sepas que al menos el 80% de las herramientas a
            ingresar deben pertenecer a las marcas de GRUPO URREA (<strong
              >URREA, SURTEK o FOY</strong
            >), de lo contrario <strong>No podrás</strong> ingresarlas a las
            instalaciones.
          </div>
          <br />
          <div class="text-center body-2">
            URREA HERRAMIENTAS MÉXICO, agradece tu comprensión.
          </div>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: space-around;">
          <v-btn
            depressed
            class="rounded-pill"
            block
            dark
            color="teal darken-1"
            @click="warningMarcas = false"
            >de acuerdo</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBarErrors
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <DialogInstruccionesForm
      v-if="showInstructions"
      :showInstructions="showInstructions"
      :idTipo="idTipo"
      @closeInstructions="showInstructions = false"
    />

    <LoadingBar :dialog="muestraLoading" :text="textoLoading" />

    <PersonaSendMail
      v-if="showSendMail"
      :showSendMail="showSendMail"
      defaultMsg=""
      receptor="mromero@urrea.net"
      :title="
        'Problema en registro de ingreso de material [' + $route.params.id + ']'
      "
      :esVisita="true"
      @hideSendMail="hideSendMail"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SnackBarErrors from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import DialogInstruccionesForm from "./componentsFormVisitas/DialogInstruccionesForm.vue";
import LoadingBar from "../views/generated/DesarrolloOrganizacional/components/loadingBar";
import PersonaSendMail from "./PersonaDialogSendMail";

export default {
  name: "formulario-material-ingresar",
  components: {
    SnackBarErrors,
    DialogInstruccionesForm,
    LoadingBar,
    PersonaSendMail,
  },
  data() {
    return {
      mensaje: "Validando token...",
      colorTexto: "black--text",
      loadingData: true,
      registroDeMateriales: 1,
      articulosPersonales: [],
      herramientas: [],
      consumibles: [],
      tiposLicencia: [],
      tiposVehiculos: [],
      catalogoHerramientas: [],
      rulesTextField: [
        (v) => !!v || "Este campo es requerido",
        (v) =>
          (v && v.length <= 50) || "This field must be less than 50 characters",
      ],
      rulesNumber: [
        (v) => !!v || "Este campo es requerido",
        (v) => v >= 0 || "Este campo debe ser mayor a 0.",
      ],
      rulesTextArea: [
        (v) => !!v || "Este campo es requerido",
        (v) =>
          (v && v.length <= 100) ||
          "This field must be less than 100 characters",
      ],
      visitante: {},
      imageArtPersonal: null,
      imageConsumibles: null,
      imageHerramienta: null,
      imageVehiculo: null,
      dialogPrincipal: true,
      showInstructions: false,
      loadingArtsPersonales: true,
      loadingConsumibles: true,
      loadingHerramientas: true,
      validFormArticulosPersonales: true,
      validConsumibles: true,
      validHerramientas: true,
      validVehiculo: true,
      dialogWarnings: false,
      registroArtPersonales: false,
      registroConsumibles: false,
      muestraBarra: false,
      switchArticulosPersonales: false,
      switchConsumibles: false,
      switchHerramientas: false,
      switchVehiculo: false,
      switchNuestrasMarcas: false,
      hideForm: false,
      warningMarcas: false,
      alertArtPersonales: true,
      alertFormArtPersonales: true,
      alertConsumibles: true,
      alertFormConsumibles: true,
      alertHerramientas: true,
      alertFormHerramientas: true,
      alertVehiculo: true,
      alertFormVehiculo: true,
      loadingBtnArtsPersonales: false,
      loadingBtnHerramientas: false,
      muestraLoading: false,
      showSendMail: false,
      loadingCatalog: false,
      otraMarca: false,
      textoLoading: "",
      textoBarra: "",
      colorBarra: "",
      articuloName: "",
      articuloMarca: "",
      articuloCantidad: 0,
      tipoAdvertencia: 0,
      articuloDesc: "",
      articuloNumSerie: "",
      articuloHerramientaName: "",
      articuloHerramientaMarca: "",
      articuloHerramientaCantidad: 0,
      articuloHerramientaDesc: "",
      articuloHerramientaNumSerie: "",
      consumibleName: "",
      consumibleMarca: "",
      consumibleCantidad: 0,
      idTipo: -1,
      consumibleDesc: "",
      consumibleNumSerie: "",
      error: "",
      tipoLincenciaSelected: "",
      numeroDeLicencia: "",
      tipoDeVehiculoSelected: "",
      numeroEconomico: "",
      numeroDePlacas: "",
      poliza: "",
      responsableGps: "",
      razonSocial: "",
      numeroDeCaja: "",
      placasDeCaja: "",
      numeroDeSello: "",
    };
  },
  computed: {
    numberColumForm() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? 12 : 4;
    },
    numberColumTable() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name) ? 12 : 8;
    },
    paddingPrincipal() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name)
        ? "pa-1"
        : "pa-7";
    },
    fileRulesArtPersonal() {
      const maxSize = 3 * 1024 * 1024;

      return [
        (file) => {
          if (!file) {
            return "Selecciona un archivo";
          }

          if (file.size > maxSize) {
            return `El archivo debe ser menor o igual a 5 MB`;
          }

          // Si pasa todas las reglas, devuelve true
          return true;
        },
      ];
    },
    isSmallScreen() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name);
    },
    validaForms() {
      if (
        this.switchArticulosPersonales ||
        (this.articulosPersonales.length > 0 && [2,4].includes(this.visitante.id_tipo) ) ||
        ((this.switchArticulosPersonales ||
          this.articulosPersonales.length > 0) &&
          (this.switchConsumibles || this.consumibles.length > 0) &&
          (this.switchHerramientas ||
            this.herramientas.length > 0 ||
            (this.AccionVisitante == 0 && this.visitante.id_tipo == 3)) &&
          (this.switchVehiculo ||
            (this.tipoLincenciaSelected != "" &&
              this.poliza != "" &&
              this.tipoVehiculoSelected != "" &&
              this.imageVehiculo != null)))
      ) {
        return true;
      }
      return false;
    },
    porcentajeHerramientasUrrea() {
      if (this.herramientas.length > 0) {
        const marcasUrrea = this.herramientas.filter((herramienta) =>
          ["URREA", "SURTEK", "FOY"].includes(herramienta.marca.toUpperCase())
        );
        return (marcasUrrea.length / this.herramientas.length) * 100;
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    try {
      this.visitante = await this.getDataVisitante();

      if ("error" in this.visitante) {
        this.hideForm = true;
      } else {
        this.idTipo = this.visitante.id_tipo;
        this.showInstructions = true;
        this.articulosPersonales = await this.getDataVisitanteArticulosPersonales().catch(
          (e) => {
            alert(e.error);
          }
        );
        this.consumibles = await this.getDataConsumibles().catch((e) => {
          alert(e.error);
        });
        this.herramientas = await this.getDataHerramientas().catch((e) => {
          alert(e.error);
        });
        // this.catalogoHerramientas = await this.getToolsCatalog()

        this.tiposLicencia = await this.getTiposLicencia();
        this.tiposVehiculos = await this.getTiposVehiculo();
      }
    } catch (error) {
      this.hideForm = true;
      alert(error);
    }
  },
  methods: {
    openDialogWarnings(tipo) {
      this.tipoAdvertencia = tipo;
      this.dialogWarnings = true;
    },
    async setMarca(tool) {
      this.articuloHerramientaMarca = await this.getMarca(tool);
    },
    async getMarca(tool) {
      const params = {
        Opcion: 37,
        token_visita: this.$route.params.key,
        entrada: tool,
      };

      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              reject("");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getToolsCatalog() {
      if (this.articuloHerramientaName.length >= 3) {
        this.loadingCatalog = true;
        const params = {
          Opcion: 36,
          token_visita: this.$route.params.key,
          entrada: this.articuloHerramientaName,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              this.catalogoHerramientas = response.data.respuesta;
            } else {
              this.catalogoHerramientas = [];
            }
          })
          .catch((error) => {
            alert(error);
          })
          .finally(() => {
            this.loadingCatalog = false;
          });
      }
    },
    async getDataVisitante() {
      let params = {
        Opcion: 8,
        token_visita: this.$route.params.key,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.respuesta);
            } else {
              resolve({
                error:
                  "Token expirado. Ya no puedes responder este formulario pues o bien la visita se canceló o el material ya se registró",
              });
            }
          })
          .catch((error) => {
            reject({ error: error });
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    async getDataVisitanteArticulosPersonales() {
      let idArray = this.$route.params.id.split("-");
      let params = {
        Opcion: 15,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              let data = [];
              response.data.materiales.map((a) => {
                data.push({
                  id: a.ID,
                  nombre: a.NOMBRE,
                  marca: a.MARCA,
                  descripcion: a.DESCRIPCION,
                  cantidad: a.CANTIDAD,
                  numSerie: a.NO_SERIE,
                  evidencia: null,
                  filename: a.FILE_TITLE,
                });
              });
              resolve(data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject({ error: error });
          })
          .finally(() => {
            this.loadingArtsPersonales = false;
          });
      });
    },
    async getDataConsumibles() {
      let idArray = this.$route.params.id.split("-");
      let params = {
        Opcion: 16,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              let data = [];
              response.data.materiales.map((a) => {
                data.push({
                  id: a.ID,
                  nombre: a.NOMBRE,
                  marca: a.MARCA,
                  descripcion: a.DESCRIPCION,
                  cantidad: a.CANTIDAD,
                  numSerie: a.NO_SERIE,
                  evidencia: null,
                  filename: a.FILE_TITLE,
                });
              });
              resolve(data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject({ error: error });
          })
          .finally(() => {
            this.loadingConsumibles = false;
          });
      });
    },
    async getDataHerramientas() {
      let idArray = this.$route.params.id.split("-");
      let params = {
        Opcion: 17,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              let data = [];
              response.data.materiales.map((a) => {
                data.push({
                  id: a.ID,
                  nombre: a.NOMBRE,
                  marca: a.MARCA,
                  descripcion: a.DESCRIPCION,
                  cantidad: a.CANTIDAD,
                  numSerie: a.NO_SERIE,
                  evidencia: null,
                  filename: a.FILE_TITLE,
                });
              });
              resolve(data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject({ error: error });
          })
          .finally(() => {
            this.loadingHerramientas = false;
          });
      });
    },
    async getTiposLicencia() {
      let params = {
        Opcion: 9,
        token_visita: this.$route.params.key,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.licencias);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject({ error: error });
          });
        // .finally(() => {
        //   this.loadingData = false;
        // });
      });
    },
    async getTiposVehiculo() {
      let params = {
        Opcion: 10,
        token_visita: this.$route.params.key,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              resolve(response.data.vehiculos);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            reject({ error: error });
          });
        // .finally(() => {
        //   this.loadingData = false;
        // });
      });
    },
    muestraSnackBar(color, texto) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
    async pushInList(type) {
      if (type === 1) {
        if (this.$refs.formArticulosPersonales.validate()) {
          let fileBase64 = "";
          if (this.imageArtPersonal != null) {
            fileBase64 = await this.readFileAsBase64(this.imageArtPersonal);
          }

          const params = {
            Opcion: 19,
            token_visita: this.$route.params.key,
            id: this.$route.params.id,
            articuloPresonal: {
              nombre: this.articuloName,
              marca: this.articuloMarca,
              descripcion: this.articuloDesc,
              cantidad: this.articuloCantidad,
              numSerie: this.articuloNumSerie,
              evidencia: fileBase64,
              filename: this.imageArtPersonal.name,
            },
          };
          axios
            .post(
              `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
              params
            )
            .then(async (response) => {
              if (response.data.status === "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    "teal darken-1",
                    response.data.respuesta.message
                  );
                  this.loadingArtsPersonales = true;
                  this.articulosPersonales = await this.getDataVisitanteArticulosPersonales().catch(
                    (e) => {
                      alert(e.error);
                    }
                  );
                } else {
                  this.muestraSnackBar(
                    "pink darken-3",
                    response.data.respuesta.message
                  );
                }
              } else {
                this.muestraSnackBar(
                  "pink darken-3",
                  "No se pudo registrar el item. " + this.articuloName
                );
              }
            })
            .catch((e) => {
              this.muestraSnackBar("pink darken-3", e);
            })
            .finally(() => {
              this.loadingBtnArtsPersonales = false;
            });

          this.$refs.formArticulosPersonales.reset();
        } else {
          this.loadingBtnArtsPersonales = false;
          this.muestraSnackBar(
            "pink darken-3",
            "Es necesario que llenes todos los campos."
          );
        }
      } else if (type === 2) {
        if (this.$refs.formConsumibles.validate()) {
          let fileBase64 = "";
          if (this.imageConsumibles != null) {
            fileBase64 = await this.readFileAsBase64(this.imageConsumibles);
          }

          const params = {
            Opcion: 22,
            token_visita: this.$route.params.key,
            id: this.$route.params.id,
            consumible: {
              nombre: this.consumibleName,
              marca: this.consumibleMarca,
              descripcion: this.consumibleDesc,
              cantidad: this.consumibleCantidad,
              numSerie: this.consumibleNumSerie,
              evidencia: fileBase64,
              filename: this.imageConsumibles.name,
            },
          };
          axios
            .post(
              `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
              params
            )
            .then(async (response) => {
              if (response.data.status === "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    "teal darken-1",
                    response.data.respuesta.message
                  );
                  this.loadingConsumibles = true;
                  this.consumibles = await this.getDataConsumibles().catch(
                    (e) => {
                      alert(e.error);
                    }
                  );
                } else {
                  this.muestraSnackBar(
                    "pink darken-3",
                    response.data.respuesta.message
                  );
                }
              } else {
                this.muestraSnackBar(
                  "pink darken-3",
                  "No se pudo registrar el item. " + this.articuloName
                );
              }
            })
            .catch((e) => {
              this.muestraSnackBar("pink darken-3", e);
            })
            .finally(() => {
              this.loadingBtnConsumibles = false;
            });
          this.$refs.formConsumibles.reset();
        } else {
          this.muestraSnackBar(
            "pink darken-3",
            "Es necesario que llenes todos los campos."
          );
        }
      } else if (type === 3) {
        if (this.$refs.formHerramientas.validate()) {
          let fileBase64 = "";
          if (this.imageHerramienta != null) {
            fileBase64 = await this.readFileAsBase64(this.imageHerramienta);
          }

          const params = {
            Opcion: 24,
            token_visita: this.$route.params.key,
            id: this.$route.params.id,
            herramienta: {
              nombre: this.articuloHerramientaName,
              marca: this.articuloHerramientaMarca,
              descripcion: this.articuloHerramientaDesc,
              cantidad: this.articuloHerramientaCantidad,
              numSerie: this.articuloHerramientaNumSerie,
              evidencia: fileBase64,
              filename: this.imageHerramienta.name,
            },
          };
          axios
            .post(
              `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
              params
            )
            .then(async (response) => {
              if (response.data.status === "OK") {
                if (response.data.respuesta.status_tran == "OK") {
                  this.muestraSnackBar(
                    "teal darken-1",
                    response.data.respuesta.message
                  );
                  this.loadingHerramientas = true;
                  this.herramientas = await this.getDataHerramientas().catch(
                    (e) => {
                      alert(e.error);
                    }
                  );
                } else {
                  this.muestraSnackBar(
                    "pink darken-3",
                    response.data.respuesta.message
                  );
                }
              } else {
                this.muestraSnackBar(
                  "pink darken-3",
                  "No se pudo registrar el item. " + this.articuloName
                );
              }
            })
            .catch((e) => {
              this.muestraSnackBar("pink darken-3", e);
            })
            .finally(() => {
              this.loadingBtnHerramientas = false;
            });
          this.$refs.formHerramientas.reset();
        } else {
          this.muestraSnackBar(
            "pink darken-3",
            "Es necesario que llenes todos los campos."
          );
        }
      }
    },
    validaCambioSwitchAriculosPersonales() {
      if (this.switchArticulosPersonales) {
        console.log("Si entra");
        this.registroDeMateriales++;
        this.registroArtPersonales = true;
      }
      if (this.switchConsumibles) {
        this.registroDeMateriales++;
        this.registroConsumibles = true;
      }
      if (this.switchHerramientas) {
        this.registroDeMateriales++;
        this.registroHerramientas = true;
      }
    },
    async saveData() {
      if (this.validaForms) {
        this.textoLoading = "Guardando material registrado...";
        this.muestraLoading = true;
        let fileBase64 = "";
        if (this.imageVehiculo != null) {
          fileBase64 = await this.readFileAsBase64(this.imageVehiculo);
        }
        const params = {
          Opcion: 11,
          token_visita: this.$route.params.key,
          id: this.$route.params.id,
          tipo: this.visitante.id_tipo,
          accion: this.visitante.AccionVisitante,
          switchVehiculo: this.switchVehiculo,
          tipoLincenciaSelected: this.tipoLincenciaSelected,
          numeroDeLicencia: this.numeroDeLicencia,
          tipoDeVehiculoSelected: this.tipoDeVehiculoSelected,
          numeroEconomico: this.numeroEconomico,
          numeroDePlacas: this.numeroDePlacas,
          poliza: this.poliza,
          responsableGps: "",
          razonSocial: this.razonSocial,
          numeroDeCaja: this.numeroDeCaja,
          placasDeCaja: this.placasDeCaja,
          numeroDeSello: this.numeroDeSello,
          vehiculoEvidencia: fileBase64,
          filename: this.imageVehiculo.name,
        };
        axios
          .post(
            `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
            params
          )
          .then((response) => {
            if (response.data.status == "OK") {
              if (response.data.respuesta.status_tran == "OK") {
                this.muestraSnackBar(
                  "teal darken-1",
                  response.data.respuesta.message
                );
                // location.reload();
              } else {
                this.muestraSnackBar(
                  "pink darken-3",
                  response.data.respuesta.message
                );
              }
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                "Ocurrió un error. No se guardo el registro."
              );
            }
          })
          .catch((e) => {
            this.muestraSnackBar("pink darken-3", e);
          })
          .finally(() => {
            this.muestraLoading = false;
          });
      }
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("No file provided."));
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    },
    async openFile(idMaterial, tipo, file) {
      let idArray = this.$route.params.id.split("-");
      let params = {
        Opcion: 20,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
        filename: idMaterial + "_" + file,
        tipo: tipo,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        );

        if (response.data.status === "OK") {
          let arrayDataFile = response.data.file.split(",");
          const blob = await this.base64ToBlob(
            arrayDataFile[1],
            arrayDataFile[0]
          );
          const fileUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          let fileNameArray = file.split(".");
          link.href = fileUrl;
          link.download = fileNameArray[0] + ".jpg";
          link.click();
        } else {
          alert(
            "Parece que el archivo está dañado o no existe. Se recomienda reemplazar."
          );
        }
      } catch (error) {
        console.error("Ocurrió un error:", error);
        alert("Ocurrió un error al descargar el archivo.");
      }
    },
    async base64ToBlob(base64, contentType = "") {
      // let arrayBase64 = base64.split(",")
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {
        type: contentType,
      });
    },
    deleteArticuloPersonal(item) {
      let idArray = this.$route.params.id.split("-");
      const params = {
        Opcion: 21,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
        idMaterial: item.id,
        filename: item.filename,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
              this.loadingArtsPersonales = true;
              this.articulosPersonales = await this.getDataVisitanteArticulosPersonales().catch(
                (e) => {
                  alert(e.error);
                }
              );
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        })
        .finally(() => {
          this.loadingBtnArtsPersonales = false;
        });
    },
    deleteConsumible(item) {
      let idArray = this.$route.params.id.split("-");
      const params = {
        Opcion: 23,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
        idMaterial: item.id,
        filename: item.filename,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
              this.loadingConsumibles = true;
              this.consumibles = await this.getDataConsumibles().catch((e) => {
                alert(e.error);
              });
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        })
        .finally(() => {
          this.loadingBtnArtsPersonales = false;
        });
    },
    deleteHerramienta(item) {
      let idArray = this.$route.params.id.split("-");
      const params = {
        Opcion: 25,
        token_visita: this.$route.params.key,
        idVisitante: idArray[1],
        idMaterial: item.id,
        filename: item.filename,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-RegistroVisitantes.php`,
          params
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            if (response.data.respuesta.status_tran == "OK") {
              this.muestraSnackBar(
                "teal darken-1",
                response.data.respuesta.message
              );
              this.loadingHerramientas = true;
              this.herramientas = await this.getDataHerramientas().catch(
                (e) => {
                  alert(e.error);
                }
              );
            } else {
              this.muestraSnackBar(
                "pink darken-3",
                response.data.respuesta.message
              );
            }
          } else {
            this.muestraSnackBar(
              "pink darken-3",
              "No se pudo registrar el item. " + this.articuloName
            );
          }
        })
        .catch((e) => {
          this.muestraSnackBar("pink darken-3", e);
        });
    },
    hideSendMail(respuesta) {
      this.showSendMail = false;
      if (respuesta == -1) {
        this.muestraSnackBar("pink darken-3", "No se pudo enviar el correo");
      } else if (respuesta == 1) {
        this.muestraSnackBar("teal darken-1", "Correo enviado con éxito");
      }
    },
  },
};
</script>

<style lang="scss">
.fondo-form {
  background-color: #eceff1;
}
</style>
