<template>
  <div>
    <v-toolbar color="primary" class="center-item-in-div">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        max-width="250"
      ></v-img>
    </v-toolbar>
    <v-container class="center-item-in-div">
      <v-sheet
        v-if="loadingData"
        width="500"
        height="400"
        class="pa-5 center-item-in-div"
      >
        <v-progress-circular
          :size="150"
          :width="10"
          color="new_primary"
          indeterminate
        >
          <label class="overline text-center font-weight-bold"
            >validando <br />
            información</label
          >
        </v-progress-circular>
      </v-sheet>
      <v-sheet v-else width="500" height="400" class="pa-5 center-item-in-div">
        <div v-if="respuesta">
          <label class="overline font-weight-bold text-center" :class="respuesta.status_tran === 'OK' ? 'teal--text text--darken-1' : 'pink--text text--darken-3'">
            {{respuesta.message}}
          </label>
        </div>
        <div v-else>
          <label class="overline text-center">
            Ocurrió un error desconocido.
          </label>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "autoriza-incidencia",
  components: {},
  data() {
    return {
      loadingData: true,
      respuesta: {},
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
  },
  async mounted() {
    // console.log(JSON.stringify(this.$route.params, null, 2))
    this.respuesta = await this.updateStatusIncidence().catch((e) => {
      alert(e);
    });
  },
  methods: {
    updateStatusIncidence() {
      let params = {
        Opcion: 7,
        tokenIncidence: this.$route.params.token,
        key: this.$route.params.key,
      };
      return new Promise((res, rej) => {
        axios
          .post(
            `${this.$store.getters.getHost}/Incidencias/ws-incidencias-autorizacion.php`,
            params
          )
          .then(async (response) => {
            if (response.data.status == "OK") {
              res(response.data.respuesta);
            } else {
              rej({});
            }
          })
          .catch((error) => {
            rej(error);
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
  },
};
</script>
