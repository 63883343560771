<template>
  <v-dialog
    v-model="showInstructions"
    max-width="550"
    content-class="fix-border-dialog"
  >
    <v-card class="rounded-xl">
      <v-card-title class="py-2 pr-3">
        <div class="subtitle-1 text-uppercase primary--text font-weight-bold">
          ¡Bienvenid@ a GRUPO URREA!
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('closeInstructions')">mdi-close</v-icon>
      </v-card-title>
        <v-sheet class="pa-5" height="400">
            <div
                style="display: flex; justify-content: start; align-items: center;"
            >
                <v-avatar>
                <v-icon large color="blue darken-4">
                    mdi-account-hard-hat-outline
                </v-icon>
                </v-avatar>
                <div
                class="text-uppercase font-weight-bold blue-grey--text text--darken-2"
                >
                Querémos agilizar tu proceso de ingreso
                </div>
            </div>
            <div class="px-5">
            <p class="text-justify body-2">
                Por eso te invitamos a llenar este formulario para que puedas
                registrar el material que vas a ingresar. Por ejemplo.
                <ul>
                <li>Artículos personales.</li>
                <li>En caso de que seas proveedor:
                    <ul>
                        <li>Consumibles.</li>
                        <li>Herramientas de trabajo URREA.</li>
                        <li>Vehículos.</li>
                    </ul>
                </li>
                </ul>
            </p>
            <div class="center-item-in-div-column">
                <p class="text-center font-weight-bold text-uppercase light-blue--text text--darken-2">
                    ¡esperemos que disfrutes tu visita!
                </p>
                <v-img :src="require('@/assets/img/Grupo-Urrea-2023.png')" height="100" width="300" contain></v-img>
            </div>
        </div>
        </v-sheet>
      <v-card-actions>
        <v-btn
          class="rounded-pill font-weight-bold"
          depressed
          dark
          large
          block
          color="teal darken-1"
          @click="$emit('closeInstructions')"
        >
          continuar
          <v-icon right>mdi-arrow-expand-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-instrucciones-form",
  props: ["showInstructions", "idTipo"],
  data: () => ({
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
  }),
};
</script>
