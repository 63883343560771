import GeneralLoginForm from './components/LoginForm'
import GeneralTransporteDialog from './components/TransporteDialog'
import GeneralContactanosDialog from './components/ContactanosDialog'
import PersonaAluExterno from '../../../components/PersonaAluExterno.vue'
import PersonaAluExterno2 from '../../../components/PersonaAluExterno2.vue'
import axios from 'axios'

export default {
    name: 'login',
    components: {
        GeneralLoginForm,
        GeneralTransporteDialog,
        GeneralContactanosDialog,
        PersonaAluExterno,
        PersonaAluExterno2
    },
    props: [],
    data: () => ({
        dialogTransporte: false,
        dialogContactanos: false,
        dialogAlu: false,
        dialogAlu2: false,

        direction: 'top',
        fab: false,
        fling: false,
        hover: false,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: 'slide-y-reverse-transition',
    }),

    mounted() {
        localStorage.clear();
        //  window.document.title = "DH:URREA - Inicio de sesión.";
        this.saveMonitoreo();

    },
    methods: {
        openAlu() {
            this.dialogAlu = true;
        },
        openRutas() {
            //("transporte")
            this.dialogTransporte = true;
        },
        saveMonitoreo() {
            let params = {
                Opcion: 10,
                pantalla: "login",
                token: '0'
            }
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    console.log("monitoreo guardado. " + response.data)
                })
        }
    }
}