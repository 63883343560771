<template>
  <div>
    <v-toolbar color="primary" class="center-item-in-div">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        max-width="250"
      ></v-img>
    </v-toolbar>
    <v-container class="center-item-in-div">
      <v-sheet
        v-if="loadingData"
        width="500"
        height="400"
        class="pa-5 center-item-in-div"
      >
        <v-progress-circular
          :size="150"
          :width="10"
          color="new_primary"
          indeterminate
        >
          <label class="overline text-center font-weight-bold"
            >validando <br />
            información</label
          >
        </v-progress-circular>
      </v-sheet>
      <v-sheet v-else width="500" height="400" class="pa-5 center-item-in-div-column" :class="respuesta.status == 'OK' ? 'teal--text text--darken-2' : 'pink--text text--darken-2'">
        <v-icon v-if="respuesta.status == 'OK'" style="font-size: 8rem;" color="teal darken-2">
            mdi-check-circle-outline
        </v-icon>
        <v-icon v-else style="font-size: 8rem;" color="pink darken-2">
            mdi-close-circle-outline
        </v-icon>
          <label class="overline font-weight-bold">{{ respuesta.message }}</label>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "autoriza-incidencia",
  components: {},
  data() {
    return {
      loadingData: true,
      respuesta: {},
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.name === "xs" ? true : false;
    },
  },
  async mounted() {
    this.respuesta = await this.setDesacansoLaborado();
  },
  methods: {
    setDesacansoLaborado() {
      const params = {
        folio: this.$route.params.emplid, 
        tipo: this.$route.params.key === "masivo" ? 2 : 1,
        opcion: this.$route.params.opcion, 
        token: this.$route.params.key
    };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/autoriza-solicitud`,
            params
          )
          .then(async (response) => {
            if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
  },
};
</script>
