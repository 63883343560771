<template>
  <div>
    <v-toolbar color="primary" class="text-center">
      <v-img
        :src="require('@/assets/icons/dh-logo.png')"
        max-height="150"
        max-width="250"
      ></v-img>
    </v-toolbar>
    <v-container style="max-height: 80vh;">
      <CargaApegoPerfilDeCandidato
        :esInterno="false"
        :token="$route.params.key"
        :idCandidato="$route.params.id"
      />
    </v-container>
  </div>
</template>

<script>
import CargaApegoPerfilDeCandidato from "../views/generated/AdmnVacantes/components/componentsCandidato/CargaApegoPerfilDeCandidato.vue";
export default {
  name: "llena-apego-perfil",
  components: { CargaApegoPerfilDeCandidato },
  data() {
    return {};
  },
};
</script>
