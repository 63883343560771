<template>
  <!------------------------------------------------ TEMPLATE -->
  <v-dialog v-model="visible" scrollable max-width="800px" persistent content-class="fix-border-dialog">
    <v-card class="rounded-xl" color="primary">
      <v-card-title class="primary white--text subtitle-1 text-uppercase font-weight-bold"> 
        Contáctanos 
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('closed')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <v-alert
          border="left"
          dismissible
          :color="colorAlert"
          v-model="alert"
          dark
        >
          {{ mensajeAlert }}
        </v-alert>
        <v-row dense class="pa-0 ma-0">
          <v-col cols="12" xs="12" sm="6" md="6" class="primary white--text subtitle-1 pa-5">
            <p>
              En Grupo Urrea estamos comprometidos con<br />
              <b class="font-weight-bold">La política de puertas abiertas</b><br />
              Si sufres alguna situación de:<br />
              - Inconformidad<br />
              - Abuso<br />
              - Acoso sexual u otros conflictos<br /><br />

              Envíanos tu inquietud y se manejara de forma 100% confidencial.<br /><br />

              También puedes hacerlo a través de la Línea confidencial URREA:
              800 22 87732
            </p>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6"  class="pa-5 center-item-in-div-column">
            <v-card class="rounded-xl pa-5" elevation="10" style="width: 100% !important;">
            <v-text-field
              outlined
              dense
              class="rounded-lg"
              label="Asunto"
              style="width: 100% !important;"
              v-model="asunto"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              class="rounded-lg"
              label="Nombre y/o Departamento"
              style="width: 100% !important;"
              v-model="nomDepto"
            ></v-text-field>
            <v-textarea outlined rows="3" style="width: 100% !important;" class="rounded-lg" v-model="comentarios">
              <template v-slot:label>
                <div>Comentarios</div>
              </template>
            </v-textarea>
            <v-btn
              block
              color="success"
              elevation="0"
              class="rounded-lg"
              large
              @click="validaContactanos()"
              :loading="loadingGuardar"
              >Enviar</v-btn
            >
          </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="primary py-5">
        
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// ---------------------------------------------- CONTROLLER
import axios from "axios";
export default {
  name: "general-contactanos-dialog",
  data: () => ({
    visible: false,
    asunto: "",
    nomDepto: "",
    comentarios: "",
    alert: false,
    mensajeAlert: "",
    colorAlert: "",
    respuestaApi: "",
    loadingGuardar: false,
    iconAlert: "",
  }),
  props: ["dialogVisible"],
  watch: {
    dialogVisible: function (isVisible) {
      this.visible = isVisible;
    },
    visible: function (isVisible) {
      if (!isVisible) this.$emit("closed");
    },
  },
  methods: {
    validaContactanos() {
      if (this.comentarios == "" || this.nomDepto == "" || this.asunto == "") {
        this.mensajeAlert = "Todos los campos deben estar llenos.";
        this.colorAlert = "pink";
        this.iconAlert = "error";
        this.alert = true;
      } else {
        this.loadingGuardar = true;
        let params = {
          Opcion: 1,
          asunto: this.asunto,
          nombre: this.nomDepto,
          comentario: this.comentarios,
        };
        axios
          .post(`${this.$store.getters.getHost}/Login/ws-contacto.php`, params)
          .then((response) => {
            var data = response.data.asunto;
            this.respuestaApi = data;
            this.validaRespuestaApi();
          })
          .catch(function (e) {
            let result = e.toString().includes("401");
            if (result) {
              window.location.reload();
            } else {
              alert(e);
            }
          });
      }
    },
    validaRespuestaApi() {
      this.loadingGuardar = false;
      if (Number.isInteger(parseInt(this.respuestaApi, 10))) {
        this.comentarios = "";
        this.nomDepto = "";
        this.asunto = "";
        this.mensajeAlert =
          "Listo. Hemos guardado tus comentarios, en breve se dará solución. Gracias. Folio para seguimiento: " + this.respuestaApi;
        this.colorAlert = "success";
        this.iconAlert = "success";
        this.alert = true;
      }
    },
  },
};
</script>

<style lang="sass"> // ---------------------------------------------- STYLES
</style>
